import { Paper, Grid } from '@mui/material';
import React from 'react';

const zoom  =   12;
let checkGoogleInterval;
let beforeCenter;
let windowGoogle;
let map=false;
let nuevoNivelDeZoom=14;

const Mapa=({locations,centrar,reOrder,setReOrder})=>{

    const [center,setCenter]        =   React.useState(false)
    const [location,setlocation]    =   React.useState(false)
    const [loading,setloading]      =   React.useState(false)
    const mapRef                    =   React.useRef(false);

    
    let directionsDisplay;
    let directionsService;
    let puntoA;
    let puntoB;
    let _locations=[];

    const loadGoogleMaps = () => {
        if (window.google){
            clearInterval(checkGoogleInterval);
            preRender();
        } else{
            // Crea un elemento script y configura su src para cargar la API de Google Maps
            const script = document.createElement('script');
            script.src = 'https://maps.googleapis.com/maps/api/js?key='+process.env.REACT_APP_GOOGLEMAP;

            // Agrega el script al elemento head del documento
            document.head.appendChild(script);

            // Agrega un evento onload para detectar cuando se haya cargado la API
            script.onload = () => {
                clearInterval(checkGoogleInterval);
                preRender();
            };
        }
    };

    /*
        Con esto centro el mapa para dar detalle del item
    */
    React.useEffect(() => {
        if (centrar&&map) {
            setCenter({lat:centrar.lat,lng:centrar.lng})
            map.setCenter({lat:centrar.lat,lng:centrar.lng});  
            map.setZoom(nuevoNivelDeZoom);   
        }       
    }, [centrar]);


    /*
        Con esto centro el mapa para dar detalle del item
    */
    React.useEffect(() => {
        //if (center) {
            _locations=[]
            setloading(true)
            setCenter(false);
            setlocation(reOrder);            
            setTimeout(() => {
                preRender(true)
                setReOrder(false);
            }, 1500);
        //}       
    }, [reOrder]);
    
    
    
    React.useEffect(() => {
        checkGoogleInterval     =   setInterval(loadGoogleMaps, 1000); // Verifica cada segundo
    
        return () => {
            clearInterval(checkGoogleInterval); 
        };

      }, []);

    const preRender=(reinit)=>{
        
        let _center      = {

        }
        
        if (locations&&locations[0]&&(!center||reinit)) {
            
            locations.map((row,key)=>{
                _locations.push({
                    id: key,
                    name: row.address_components,
                    position: { lat: row.lat, lng: row.lng }
                })
            })

            setlocation(_locations)
            
            puntoA =  { 
                                lat: locations[0].lat, 
                                lng: locations[0].lng 
                            };
            puntoB =  {   
                                lat: locations[1].lat, 
                                lng: locations[1].lng 
                            };
            _center      =  {
                                lat: (puntoA.lat + puntoB.lat) / 2,
                                lng: (puntoA.lng + puntoB.lng) / 2,                        
                            }
            setCenter(_center)    
            beforeCenter=_center
        }
        
        const mapOptions = {
            zoom: zoom,
            center: center||_center,
        };
        //if(!map){
            map                     =   new window.google.maps.Map(mapRef.current, mapOptions);   
        //}
        
        windowGoogle            =   window.google;
        renderRoute();  
    }

    const renderRoute=()=>{

        if (_locations&&!_locations[0]) {
            _locations  =   location;            
        }
        
        const objConfigDR={
            map:map
        }
        

        const objConfigDS = {
            waypoints: _locations.slice(1, -1).map((punto) => ({
                location: punto.position,
                stopover: true
            })),
            origin: _locations[0].position,
            destination: _locations[_locations.length - 1].position,
            travelMode: 'DRIVING'
        };
          
        directionsService   =   new window.google.maps.DirectionsService(objConfigDS);        
        directionsDisplay   =   new window.google.maps.DirectionsRenderer(objConfigDR);          

        directionsService.route(objConfigDS, (result, status) => {
            if (status === 'OK') {
                directionsDisplay.setDirections(result);
                setloading(false)
            }
        });
        
        
    }

    return      <>
                    
                    <Paper sx={{    
                                    width:"100%",
                                    minHeight:308,
                                    mb:2
                                }}>
                        
                        <Grid sx={{pl:2,pt:2}}>
                            Ruta creada                                    
                        </Grid>
                        <Grid className='position-relative'>
                            {   
                                loading&&(
                                    <Grid className='position-loading'>
                                        [Calculando...]
                                    </Grid>
                                )
                            }
                            <Grid sx={{width:"100%",height:268, background:"#999",}} ref={mapRef}>
                                                                    
                            </Grid>
                        </Grid>
                    </Paper>
                    
                </>
                
}
export default Mapa