import  React from  'react';
import  Grid  from  '@mui/material/Grid';
import  './DashboardUsuario.css';

const   Container   =     React.forwardRef((props, ref) => {
    return  <Grid ref={ref} className='content-usuario-center'>              
              {props.children}
            </Grid>
});


export default Container
//#fb19ff
