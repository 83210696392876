import  React from  'react';
import  Grid  from  '@mui/material/Grid';
const   Container   =     React.forwardRef((props, ref) => {
    return  <Grid ref={ref} sx={{paddingLeft:2}}>
              {props.children}
            </Grid>
});


export default Container
//#fb19ff
