import React from 'react';
import Grid  from  '@mui/material/Grid';
import Button  from  '@mui/material/Button';
import Paper  from  '@mui/material/Paper';
import useFormData  from '../../../hooks/useFormData';
import Container    from '../../../components/Theme/Form';
import Input        from '../../../components/Input';
import Select        from '../../../components/Select';
import useAxios      from '../../../hooks/useAxios';
import Typography from '@mui/material/Typography';
import { useNavigate, useLocation } from "react-router-dom";
import ReactQuill     from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Gallery from "../../../components/Gallery";

let cat
const Edit = ({handleClose, data , getInit, action}) => {
  const navigate              =   useNavigate();
  const location              =   useLocation();
  const axios                 =   useAxios();
  const [summary,setSummary]  =   React.useState([])
  const [images,setImages]    =   React.useState([])
  const [value, setValue]     =   React.useState('');        
  const [categories, setCategories]     =   React.useState([]);        
  const [reset, setReset]     =   React.useState(false);  

  const endpoint          =   "api/"+process.env.REACT_APP_BACKEND_VERSION+location.pathname

  const { formData,
          onChange,
          setFormData }   =   useFormData();

  const onSubmit=(e)=>{
    e.preventDefault()
    if (!action || !formData.id) {
      axios.postData({...formData,description:value,imagenes:JSON.stringify(images)}).then((response)=>{
        if (response&&response.data&&response.data.id&&getInit) {
          //getInit(true)
         // handleClose()
         return navigate("../../productos");
        }
      })
    }else {
      axios.putData({...formData,description:value}).then((response)=>{
        if (response&&response.data&&response.data.id&&getInit) {
          //getInit(true)
          //handleClose()
          return navigate("../../productos");
        }
      })
    }
  }

  getInit=()=>{
    axios.getData(formData,endpoint).then((response)=>{
      if (response&&response.data) {
        setReset(true);
        setFormData(response.data)
        setValue(response.data.description)
        if (response.data.summary) {
          const _summary  = JSON.parse(response.data.summary)
          setSummary(_summary.imagenes)
        }        
        setTimeout(()=>{
          setReset(false)
        },500)
      }
    })
  }
  
  cat=()=>{
    axios.getData(formData,endpoint+"/categories").then((response)=>{
      if (response&&response.data) {
        setCategories(response.data);
        getInit()
      }
    })
  }

  React.useEffect(()=>{
    cat()    
  },[])



  return (  <form onSubmit={onSubmit}>
              {
                formData&&!reset&&(
                  <Container>
                    <Grid sx={{mb:4,}} align="left">
                      <Grid sx={{mb:2}}>
                        <Typography variant="h4" color="initial">
                          {
                            formData.label
                          }
                        </Typography>
                      </Grid>
                      <Grid>
                        <Paper sx={{p:3}}>
                          <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                              <Select placeholder="Categoría" 
                                      items={categories}
                                      defaultValue={formData.categoria_id} 
                                      data={formData}
                                      onChange={setFormData} 
                                      name="categoria_id" 
                                      label="Categoría"
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <Select placeholder="Estado" 
                                      items={[
                                        {
                                          label:"Encendido",
                                          id:8
                                        },
                                        {
                                          label:"Apagado",
                                          id:7
                                        },
                                      ]}
                                      defaultValue={formData.status} 
                                      data={formData}
                                      onChange={setFormData} 
                                      name="status" 
                                      label="Estado"
                              />
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <Input  placeholder="Título" 
                                      defaultValue={formData.label} 
                                      onChange={onChange} 
                                      name="label" 
                                      label="Título"
                              />
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <Input  placeholder="Precio referencia" 
                                      defaultValue={formData.price} 
                                      onChange={onChange} 
                                      name="price" 
                                      type="number" 
                                      label="Precio referencia"
                              />
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <Input  placeholder="Etiquetas" 
                                      fullWidth
                                      defaultValue={formData.tags} 
                                      onChange={onChange} 
                                      name="tags" 
                                      type="text" 
                                      label="Etiquetas"
                              />
                            </Grid> 
                            <Grid item xs={12} md={"auto"}>
                              <Button variant="contained" type="submit">
                                Guardar  
                              </Button>
                            </Grid>                       
                          </Grid>
                          <Grid sx={{mb:3,mt:3}}>
                            <ReactQuill theme="snow" value={value} onChange={setValue} />
                            {
                              formData.slug&&(
                                <Grid>
                                  <a href={formData.slug} target="_blank" rel="noreferrer">
                                    Recurso de extracción
                                  </a>
                                </Grid>
                              )
                            }
                            
                          </Grid>
                          <Grid container>
                            {
                              summary&&(
                                summary.map((row,key)=>{
                                  return  <Grid key={key} item xs={2}>
                                            <a href={row} target="_blank" rel="noreferrer">
                                              <img src={row} className="img-fluid" alt=""/>
                                            </a>
                                          </Grid>
                                })
                              )
                            }    
                          </Grid>
                        </Paper>
                      </Grid>
                      <Grid sx={{mt:2}}>
                        <Paper sx={{p:3}}>
                          <Gallery images={images} setImages={setImages} />
                        </Paper>
                      </Grid>
                      {
                        /*
                          <Upload onChange={setFormData} data={formData} name="avatar">
                            <Avatar sx={{width:100,height:100}} src={formData.avatar||data.avatar} />
                          </Upload>
                        */
                      }                  
                    </Grid>                
                  </Container>
                )
              }              
            </form>
          );
};

export default Edit;

