import React from 'react';
import Container from '../../components/Theme/AuthForm';
import { Button, Grid, Typography } from '@mui/material';
import useAxios     from '../../hooks/useAxios';
import { useNavigate } from "react-router-dom";
import StateContext from '../../helpers/ContextState';
import useAsyncStorage   from '../../hooks/useAsyncStorage';


const storage = "register-user"

const Login=()=>{
  const context                 =   React.useContext(StateContext)
  const navigate                =   useNavigate();
  const axios                   =   useAxios();
  const userStorage             =   useAsyncStorage(storage);
 

  const onSubmit = async (e)=>{
    e.preventDefault()
    context.setOpenDialog({
      loading:true
    })

    await axios.postData(userStorage.data).then((response)=>{
      if (response&&response.status==='success') {
        return navigate("/auth/register_code");
      }
    })

    context.setOpenDialog(false)

  }

  
  return  <Container label="Términos y condiciones"  onSubmit={onSubmit}>
              <Grid container spacing={2} justifyContent={"left"}>                
                <Grid item xs={12} md={7}>
                  <Typography>
                    Paga de forma segura y reservada nuestros servicios...
                  </Typography>                   
                </Grid>
                <Grid item xs={12} md={7} align="center">
                  <Grid>
                  <form>
                      <script src='https://checkout.epayco.co/checkout.js'
                          data-epayco-key='43527cf929d341355533cd76c81ec4db' 
                          class='epayco-button' 
                          data-epayco-amount='143000' 
                          data-epayco-tax='0.00'  
                          data-epayco-tax-ico='0.00'               
                          data-epayco-tax-base='143000'
                          data-epayco-name='Domicilio 100' 
                          data-epayco-description='Domicilio 100' 
                          data-epayco-currency='cop'    
                          data-epayco-country='CO' 
                          data-epayco-test='false' 
                          data-epayco-external='true' 
                          data-epayco-response='https://calive.app/paid_response'  
                          data-epayco-confirmation='https://calive.app/paid_confirm' 
                          data-epayco-button='https://multimedia.epayco.co/dashboard/btns/btn5.png'> 
                      </script> 
                  </form>
                  </Grid>                  
                </Grid>
              </Grid>              
          </Container>
}
export default Login
