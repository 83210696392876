/*set components*/
import Home  from './Home';
import DetalleOrdenServicio  from './DetalleOrdenServicio';
/*set icons*/
import HomeIcon from '@mui/icons-material/Home';


export const routes_modules = [
  {
    name: "Home",
    path: "/",
    component: Home,
    menu: true,
    private: false,
    icon: <HomeIcon />,
  },
  {
    name: "Detalle",
    path: "/detalle-order-servicio/:token",
    component: DetalleOrdenServicio,
    menu: true,
    private: false,
    icon: <HomeIcon />,
  },
];
