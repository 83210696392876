import { useEffect, useState} from 'react';
import {Tabs,Tab,Paper,Box,Typography, Grid} from '@mui/material';
import PropTypes from 'prop-types';
import useAxios from '../../hooks/useAxios';
import image1 from '../../assets/png/avatar.png';
import image2 from '../../assets/png/avatar2.png';
import { NavLink } from 'react-router-dom';

const CustomTabPanel=(props)=>{
  
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1, height:400, overflow:"hidden", overflowY:"scroll" }}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const  a11yProps=(index)=>{
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
let axios = false;
export default function ColorTabs() {
  const [value, setValue]       =   useState(0);
  const [data2, setData2]       =   useState({});  
  axios                         =   useAxios();
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(()=>{
    axios.getData().then((response)=>{
      if(response&&response.data){
        setData2(response.data)      
      }      
    })
  },[])

  

  return (
            <Box sx={{ width: '100%' }}>
              <Tabs
                value={value}
                centered
                onChange={handleChange}
                textColor="secondary"
                indicatorColor="primary"
                aria-label="secondary tabs example"
              >
                {
                  
                  Object.entries(data2)&&Object.entries(data2).length>0&&(
                    Object.entries(data2).map((row,key)=>{
                      return <Tab key={key} label={row[0]} {...a11yProps(key)}/>
                    })                    
                  )
                }
              </Tabs>
              {
                  
                Object.entries(data2)&&Object.entries(data2).length>0&&(
                  Object.entries(data2).map((row,key)=>{
                    return  <CustomTabPanel value={value} index={key}>
                              <Grid container spacing={2} justifyContent={"center"}>
                                {
                                  row[1]&& row[1].length>0&&(
                                    row[1].map((row2,key2)=>{
                                      return  <Grid key={key2} item xs={11} md={6}>
                                                <NavLink to={"/dashboard/servicios/detail/"+row2.id}>
                                                  <Paper sx={{p:1}}> 
                                                    <Grid container spacing={2}>
                                                      <Grid item xs={3} align="center">
                                                        <img className="img-fluid" src={key2 % 2 === 0?image1:image2} alt="Programandoweb"/>
                                                      </Grid>
                                                      <Grid item xs={9}>
                                                        <Grid>
                                                          <Typography component="h3" variant="h6">
                                                            {row2.razon_social||row2.cliente}
                                                          </Typography>
                                                        </Grid>
                                                        <Grid>
                                                          {
                                                            row2&&row2.direcciones2&&row2.direcciones2.length>0&&(
                                                              row2.direcciones2.map((row3,key3)=>{
                                                                return  <Grid key={key3}>
                                                                          <Typography component={"div"} sx={{fontSize:11}}>
                                                                            {row3.descripcion}
                                                                          </Typography>                                                                        
                                                                        </Grid>
                                                              })
                                                            )                                                          
                                                          }
                                                        </Grid>

                                                        {console.log(row2)}
                                                      </Grid>
                                                    </Grid>
                                                  </Paper>
                                                </NavLink>
                                              </Grid>
                                    })
                                  )
                                }
                              </Grid>                                                            
                            </CustomTabPanel> 
                  })                    
                )
              }              
            </Box>
  );
}