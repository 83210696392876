import React, { useState } from 'react';
import { Button, CardActions, CardContent, Grid, Typography, Box, Paper }       from '@mui/material';
import Container      from '../../components/Theme/DashboardUsuario';
import useAsyncStorage   from '../../hooks/useAsyncStorage';
import CardMedia from '@mui/material/CardMedia';
import useAxios       from '../../hooks/useAxios';
//import Avatar        from  '../../components/Avatar';
import {Avatar} from '@mui/material';
import StateContext   from '../../helpers/ContextState';
import RenderHtml from '../../components/RenderHtml';
import Textarea from '../../components/Input/NativeTextarea';
import { NavLink , useLocation, useParams } from 'react-router-dom';
import { deepOrange, deepPurple } from '@mui/material/colors';
import io from 'socket.io-client';
const URL = process.env.REACT_APP_SOCKET;


let getInit;

const ModalComponent  = ({row})  =>{
  
  const base_image  =   row.yoast_head_json&&row.yoast_head_json.og_image&&row.yoast_head_json.og_image[0]||""
  const image       =   base_image.url;
  

  return  <Grid >
            <CardMedia
              component="img"
              height= {
                        base_image.height>800&&base_image.height<800?(base_image.height/10):base_image.height/14
                      }                    
              image={image}
              alt="Paella dish"
            />
            <CardContent sx={{height:"40vh",overflowY:"scroll"}}>
              {
                /*
                  <Typography variant='h6'> 
                    {row.title&&row.title.rendered}
                  </Typography>
                */
              }              
              <Typography variant='p'> 
                <RenderHtml string={row.title&&row.content.rendered}/>
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small" variant="contained" sx={{mr:1}}>Contratar</Button>              
              <Button size="small" variant="outlined" component={"a"} href={row.link} target="_blank">Concerla</Button>
            </CardActions>
          </Grid>
}

const Web=()=>{
  const context                           =   React.useContext(StateContext);
  const messagesContainerRef              =   React.useRef(null);
  const axios                             =   useAxios();
  const user                              =   useAsyncStorage("user");
  const [girls,setGirls]                  =   useState([]);
  const location                          =   useLocation();
  const params                            =   useParams();
  const [messages,setMessages]            =   useState([]);
  const [loading,setLoading]              =   useState(true);
  const [chatOpen,setChatOpen]            =   useState(false);
  
  

  getInit=async()=>{
    setLoading(true)
    setMessages([]) 

    if (params["*"]!==''&&params["*"]!=='home') {
      setChatOpen(true) 
      axios.getData({},"api/v1/dashboard/comments/"+params["*"]).then((response)=>{
        if (response&&response.data&&response.data.connects) {
          setGirls(response.data.connects)
        }
        if (response&&response.data&&response.data.messages) {
          setMessages(response.data.messages)
        }
        setLoading(false)
        if (messagesContainerRef.current) {
         setTimeout(() => {          
          messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
         }, 1000);        
        }
      })
    } else {
      setChatOpen(false)
      axios.getData({},"api/v1/dashboard/comments/getAll").then((response)=>{
        if (response&&response.data&&response.data.connects) {
          setGirls(response.data.connects)
        }
        if (response&&response.data&&response.data.messages) {
          setMessages(response.data.messages)
        }
        setLoading(false)
        if (messagesContainerRef.current) {
         setTimeout(() => {          
          messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
         }, 1000);        
        }
      })      
    }     
  }

  const onSubmit=(inputs)=>{
    
    let currentDate = new Date();
    let formattedDate = `${currentDate.getDate()}/${currentDate.getMonth() + 1}/${currentDate.getFullYear()}`;
    
    let message_new = {
        mensaje: inputs.mensaje,
        fecha: formattedDate,
        autor: user.data.name,
        user_id: user.data.id
    }
    
    let messages_ = [...messages]
        messages_.push(message_new)
        setMessages(messages_)  

    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight+1000;
      setTimeout(() => {        
        messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
       }, 500);
    }

    const userId  = params["*"].split("_");

    socket.emit("send-private-message-not-admin",{message:message_new,userId:userId[1]})
    
    axios.postData({...message_new,modulo_token:params["*"]},"api/v1/dashboard/comments/send");
    
    
  }

  React.useEffect(()=>{
    getInit()    
  },[location])

  const socket = io(URL, {
    transports: ['websocket'],
    withCredentials: true,
    reconnection: true,
  });

  React.useEffect(()=>{

    const Sadmin  = context.user.roles.find(search=>search==='Super Admin');
    socket.emit("user-connected",{access_token:context.user.access_token,...context.user,admin:Sadmin?true:false})

    const onMessage = (message) => {
      if (params["*"]!==''&&params["*"]==='home') {
        getInit();  
      }else{
        setMessages((prevMessages) => [...prevMessages, message]);
        if (messagesContainerRef.current) {
          messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight+1000;
          setTimeout(() => {        
            messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
           }, 500);
        }
      }      
    };  

    socket.on("message", onMessage);
    
    return () => {
      socket.off('message', onMessage);
      socket.disconnect();
    };
    
  },[context.user,context.isOnline])

  function obtenerInicial(cadena) {
    if (!cadena) {
      return;
    }
    // Verificar si la cadena no está vacía
    if (cadena.trim().length === 0) {
      return null; // O podrías devolver un valor predeterminado o lanzar un error
    }
  
    // Obtener el primer carácter y convertirlo a mayúsculas
    const inicial = cadena.charAt(0).toUpperCase();
  
    return inicial;
  }
  

  return  <Container>   
            
            <Grid container justifyContent={"center"} spacing={1}>
              {
                girls&&girls.length>0&&(
                  girls.map((row,key)=>{
                    const autor =  row&&row.autor?row.autor.name+" "+row.autor.email:"Desconocido";
                    return  <Grid   component={NavLink} 
                                    to={"/dashboard/chat/chat_"+row.user_id} 
                                    key={key} 
                                    item 
                                    xs={"auto"} 
                                    md={2} 
                                    >
                              <Grid align="center">
                                <Avatar sx={{bgcolor: key % 2 === 0?deepOrange[500]:deepPurple[500], border:(row.new===1)?"solid 4px #fb8c00":"solid 4px #ab8c00", width:{xs:40,md:80}, height:{xs:40,md:80}}} 
                                    title={autor||process.env.REACT_APP_NAME} 
                                    alt={autor||process.env.REACT_APP_NAME} 
                                >   
                                { obtenerInicial(row&&row.autor&&row.autor.name) } { obtenerInicial(row&&row.autor&&row.autor.surname) }
                                </Avatar>
                                {row&&row.autor&&row.autor.name}    {row&&row.autor&&row.autor.surname}                                                                   
                              </Grid>       
                              
                            </Grid>
                  })
                )
              }     
            </Grid> 

            {
              chatOpen&&(
                <Grid container justifyContent={"center"} spacing={1}>
                  <Grid  item xs={11} sx={{ mt: 2, height: "76vh", overflow: "hidden", overflowY: "scroll", }} className='contenedor-mensajes' ref={messagesContainerRef}>
                    <Paper sx={{p:2}}>
                      {
                        loading&&(<Grid>Estamos consultando la base de datos...</Grid>)
                      }
                      {!loading&&messages && messages.length > 0 && (
                        messages.map((row, key) => {
                          return (
                            <Grid
                              key={key}
                              sx={{
                                width:"100%",
                                display: 'flex',
                                justifyContent: row.user_id === user.data.id ? 'flex-end' : 'flex-start',
                                mb: 1,
                              }}
                            >
                              <Box
                                sx={{
                                  background: row.user_id === user.data.id ? '#999' : '#333',
                                  color: 'white',
                                  p: 1,
                                  borderRadius: '4px',
                                  width:"80%",
                                }}
                              >
                                <Grid container>
                                  <Grid item xs={9}>
                                    <Typography variant='p' sx={{fontSize:13}}>
                                      {row.mensaje}
                                    </Typography>
                                  </Grid>           
                                  <Grid item xs={3} align="right">
                                    <Typography variant='p' sx={{fontSize:10}}>
                                      {row.fecha}
                                    </Typography>
                                  </Grid>                   
                                </Grid>                            
                              </Box>
                            </Grid>
                          );
                        })
                      )}
                    </Paper>
                  </Grid>
                  <Grid  item xs={12} sx={{my:1, background:"white", height:"8vh", borderRadius:6}}>
                      <Textarea name={"mensaje"} onSubmit={onSubmit} />                                      
                  </Grid>

                </Grid> 
              )
            }
            
            

          </Container>
}
export default Web

