import {useState,useEffect} from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import useAxios      from '../../hooks/useAxios';

export default function BasicButtonGroup({data,defaultvalue,name}) {
    
    const [data2,setData2]  =   useState(false)

    useEffect(()=>{

    },[])

    return (
        <ButtonGroup  aria-label="outlined primary button group">
            {
                data.map((row,key)=>{
                    return  <Button key={key} variant={parseInt(row.value)===parseInt(defaultvalue[name])?"contained":"outlined"}>
                                {row.label}
                            </Button>
                })
            }        
        </ButtonGroup>
    );
}