import Grid     from    '@mui/material/Grid';
import Button   from    '@mui/material/Button';
import useAxios  from '../../../hooks/useAxios';
import React, { useState } from 'react';
import Select from '../../../components/Select';

const Basic=({data,trasportadores,getInit})=>{
    const axios                     =   useAxios();
    const [inputs,setInputs]        =   useState({});

    const onChange=(e)=>{
        setInputs({transportador_id:e.name})
    }

    const onSubmit=(e)=>{
        e.preventDefault()
        axios.putData(inputs,'api/v1/dashboard/services/'+data.id).then((response)=>{
            if (response&&response.data&&response.data.id) {
                getInit(true)                
            }
        })                
    }

    React.useEffect(()=>{
        if (data) {
            setInputs(data)
        }
    },[data,setInputs])

    return (
        <form onSubmit={onSubmit}>
                <Grid container spacing={2} >
                    <Grid item xs={12} md={6}>
                        <Select defaultValue={data.transportador_id} 
                                items={trasportadores||[]}
                                required onChange={onChange} 
                                size="small" 
                                fullWidth 
                                name="name" 
                                type="text" 
                                label="Nombre"/>
                    </Grid>                    
                </Grid>
                <Grid container justifyContent="right">
                    <Grid item xs={12} md={6} align="right" sx={{mt:2}}>
                        <Button variant="contained" type="submit">
                            Guardar
                        </Button>
                    </Grid>
                </Grid>            

        </form>
    )
}
export default Basic