import { usePlacesWidget } from "react-google-autocomplete";
import { TextField, Box, InputLabel } from '@mui/material';
const Component=({   label, size, onChange }) => {

const apitemp       =   process.env.REACT_APP_GOOGLEMAP

const { ref: materialRef } = usePlacesWidget({
        apiKey: apitemp,
        strictBounds: false,
        onPlaceSelected: (place) => onChange(place),
        options: {
        types: ["establishment"],
            componentRestrictions: { country: "CO" }, 
        },
    });

    return  <Box sx={{mb:2}}>
                <InputLabel >
                    {label}
                </InputLabel>
                <TextField
                    size={size}
                    fullWidth
                    color="secondary"
                    variant="outlined"
                    inputRef={materialRef}
                />
            </Box>    


}

export default Component



