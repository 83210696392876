/*set components*/
import Home  from './Home';
import View  from './View';

/*set icons*/
import Face3Icon from '@mui/icons-material/Face3';


export const routes_modules = [
  {
    name: "Condominios",
    path: "/",
    component: Home,
    menu: true,
    private: false,
    icon: <Face3Icon />,
    title: "Condominios",
    subtitle: "Desarrollos inmobiliarios"
  },
  {
    name: "View",
    path: "/*",
    component: View,
    menu: true,
    private: false,
    icon: <Face3Icon />,
    title: "Condominios",
    subtitle: "Desarrollos inmobiliarios"
  },
];
