import React from 'react';
import StateContext from '../../../helpers/ContextState';
import Input        from '../../../components/Input';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import './UploadImportDocuments.css'
import BackupIcon from '@mui/icons-material/Backup';


const Upload=({getInit, axios,to})=>{
  const [selectedFile, setSelectedFile]       =   React.useState(null);
  const [uploadLoading,setUploadLoading]      =   React.useState(false)
  const [formData,setFormData]                =   React.useState({})
  const context                               =   React.useContext(StateContext);


  const handleFileChange = (event) => {
    setUploadLoading(true)
    setSelectedFile(event.target.files[0]);
  };

  const onChange=(event)=>{
    try {
      setFormData({ ...formData, [event.target.name]: event.target.value });
    } catch (e) {
      console.log(e);
    }
  }

  const handleSubmit=(e)=>{
    e.preventDefault()
    if (!selectedFile) {
      return
    }

    axios.postDataAttachment({file:selectedFile,...formData},to+"&format=upload_document").then(response => {
      if (response&&response.code&&response.status===200&&getInit) {
        getInit(true)
      }
      setUploadLoading(false)
      context.setOpenDialog(false)
      setSelectedFile(null);
    }).catch(error => {
        console.error(error);
    });
  }

  return  <Grid sx={{p:2,m:3}} component="form" onSubmit={handleSubmit}>
            <Grid container justifyContent="center" spacing={2}>
              <Grid item xs={12} md={6} align="center">
                <Grid className="cuadro cursor-pointer">
                  {uploadLoading?<Grid sx={{m:2}}>
                        Cargado...
                      </Grid>:<>
                      <input type="file" name="file" className="input" onChange={handleFileChange}/>
                      <Grid className="content-input">
                        <Grid><BackupIcon sx={{fontSize:40}}/></Grid>
                        <Grid sx={{fontSize:20}} className="uppercase">Cargar Archivo</Grid>
                        <Grid sx={{fontSize:12}}>Importante: subir perfectamente verificado...</Grid>
                      </Grid>
                    </>
                  }
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} align="center">
                <Grid sx={{mb:2}}><Input placeholder="Ej: Acta de asamblea 500" defaultValue={formData.name} required onChange={onChange} size="small" fullWidth name="name" type="text" label="Nombre"/></Grid>
                <Grid><Input placeholder="Ej: Asamblea con los propietarios..." defaultValue={formData.descripcion} onChange={onChange} size="small" fullWidth name="descripcion" type="text" label="Descripción General"/></Grid>
                <Grid sx={{mt:2}}>
                  <Button type="submit" fullWidth variant="contained">Guardar</Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
}
export default Upload
