import React, { useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './helpers/theme'; // Importa el tema creado
import './App.css';
import Snackbars from './components/Snackbars';
import StateContext from './helpers/ContextState';
import { routes_modules } from './routes';
import NotFound from './components/NotFound';
import NotInternet from './components/Theme/NotInternet';
import LoadingFullWidth from './components/Theme/LoadingFullWidth';
import useAsyncStorage from './hooks/useAsyncStorage';
import useInternetConnection from './hooks/useInternetConnection';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Dialog from './components/Dialog';
import Backdrop from './components/Backdrop';
import useSocket from './hooks/useSocket';
const LIMIT_TIMEOUT = process.env.REACT_APP_LIMIT_TIMEOUT || 5000; // Establece un límite de tiempo para la carga

const App       =   () => {
  const {socket}        =   useSocket();
  const isOnline        = useInternetConnection(); // Verifica si hay conexión a Internet
  const userStorage     = useAsyncStorage("user"); // Obtiene los datos de usuario almacenados en el almacenamiento asíncrono
  const [user, setUser] = useState(false); // Estado para almacenar la información del usuario
  const [loading, setLoading]           =   useState(false); // Estado para controlar si se está cargando algún contenido
  const [loadingTable, setLoadingTable] =   useState(false); // Estado para controlar si se está cargando algún contenido
  const [loadingAll, setLoadingAll]     =   useState(true); // Estado para controlar si se está cargando todo el contenido
  const [openMessage, setOpenMessage]   =   useState(false); // Estado para controlar si se muestra un mensaje
  const [openDialog, setOpenDialog]     =   useState(false); // Estado para controlar si se muestra un mensaje
  const [openBackdrop, setOpenBackdrop] =   useState(false); // Estado para controlar si se muestra un mensaje

  // Función para proporcionar valores y funciones al contexto
  const provider = () => {
    return {
      user,
      setUser,
      loading,
      setLoading,
      openMessage,
      setOpenMessage,
      loadingTable,
      setLoadingTable,
      openDialog,
      setOpenDialog,      
      openBackdrop,
      setOpenBackdrop, 
      isOnline      
    };
  };

  React.useEffect(() => {
    /* Verifico el estado del almacenamiento si el usuario está logueado */
    if (!user && userStorage.data) {
      setUser(userStorage.data);
      /*vamor a buscar si tiene privilegios de Sadmin*/
      const Sadmin  = userStorage.data.roles.find(search=>search==='Super Admin');
      socket.emit("user-connected",{access_token:userStorage.data.access_token,...userStorage.data,admin:Sadmin?true:false})
    }
    /* Establecer un temporizador para cambiar el estado de loadingAll a false después de cierto tiempo */
    setTimeout(() => setLoadingAll(false), LIMIT_TIMEOUT);
  }, [user, userStorage]);

  return (
    <ThemeProvider theme={theme}>
      <StateContext.Provider value={provider()}>
        {openDialog&&(<Dialog open={openDialog} setOpen={setOpenDialog}/>)}
        {openBackdrop&&(<Backdrop open={openBackdrop} setOpen={setOpenBackdrop}/>)}
        {loadingAll ? (
          /* Mostrar componente de carga mientras se carga todo el contenido */
          <LoadingFullWidth />
        ) : !isOnline ? (
          /* Mostrar componente para cuando no hay conexión a Internet */
          <NotInternet />
        ) : (
          <>
            <Router>
              <Routes>
                {/* Mapear y renderizar rutas */}
                {routes_modules.map((module, index) => {
                  if (module.private && !user) {
                    // Ruta privada sin inicio de sesión, redirigir al inicio de sesión
                    return (
                      <Route
                        key={index}
                        path={module.path}
                        element={<Navigate to="/auth/login" />}
                      />
                    );
                  } else if (!module.private && !module.menu) {
                    // Ruta pública y no se muestra en el menú
                    return <Route key={index} path={module.path} element={<module.component />} />;
                  } else if (!module.private && module.menu) {
                    // Ruta pública y se muestra en el menú
                    return (
                      <Route
                        key={index}
                        path={module.path}
                        element={<module.component />}
                      />
                    );
                  } else if (module.private && module.menu) {
                    // Ruta privada y se muestra en el menú
                    return (
                      <Route
                        key={index}
                        path={module.path}
                        element={<module.component />}
                      />
                    );
                  }
                  return null;
                })}
                {/* Ruta para manejar URLs no encontradas */}
                <Route path="*" element={<NotFound />} />
              </Routes>
            </Router>
          </>
        )}
        {openMessage && <Snackbars open={openMessage} setOpen={setOpenMessage} />} {/* Mostrar Snackbar si openMessage es verdadero */}
      </StateContext.Provider>
    </ThemeProvider>
  );
};

export default App;
