import { Grid, Paper } from "@mui/material";
import {formatFechaLaravel} from '../../../helpers/functions'
import Table from "../../../components/Table/Table4";
import Container    from '../../Theme/Loading';

const UserHomeServiciosDetalleServicio=({data})=>{
    return  <Container>
                <Grid container justifyContent={"center"} spacing={1}>

                    <Grid item xs={10} md={3}>
                        <Paper sx={{p:1}}>
                            <Grid component={"div"}><b>Cliente</b></Grid>
                            <Grid component={"label"}>
                                {data.razon_social}
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={10} md={3}>
                        <Paper sx={{p:1}}>
                            <Grid component={"div"}><b>Código</b></Grid>
                            <Grid component={"label"}>
                                #{data.id}
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={10} md={3}>
                        <Paper sx={{p:1}}>
                            <Grid component={"div"}><b>Fecha</b></Grid>
                            <Grid component={"label"}>
                                {formatFechaLaravel(data.created_at)}
                            </Grid>
                        </Paper>    
                    </Grid>
                    <Grid item xs={10} md={3}>
                        <Paper sx={{p:1}}>
                            <Grid component={"div"}><b>Estado Servicio</b></Grid>
                            <Grid component={"label"}>
                                {data.status}
                            </Grid>
                        </Paper>    
                    </Grid>
                    



                    <Grid item xs={10} md={3}>
                        <Paper sx={{p:1}}>
                            <Grid component={"div"}><b>Modalidad</b></Grid>
                            <Grid component={"label"}>
                                {data.modalidad}
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={10} md={3}>
                        <Paper sx={{p:1}}>
                            <Grid component={"div"}><b>Tipo de Carga</b></Grid>
                            <Grid component={"label"}>
                                {data.tipo_carga}
                            </Grid>
                        </Paper>    
                    </Grid>
                    <Grid item xs={10} md={3}>
                        <Paper sx={{p:1}}>
                            <Grid component={"div"}><b>Tipo de Servicio</b></Grid>
                            <Grid component={"label"}>
                                {data.tipo_servicio}
                            </Grid>
                        </Paper>    
                    </Grid>
                    <Grid item xs={10} md={3}>
                        <Paper sx={{p:1}}>
                            <Grid component={"div"}><b>Tipo de Vehículo</b></Grid>
                            <Grid component={"label"}>
                                {data.tipo_vehiculo}
                            </Grid>
                        </Paper>
                    </Grid>

                    <Grid item xs={12}>
                        {
                            data&&data.direcciones&&(
                                <Table data={data.direcciones}/>                                
                            )
                        }                        
                    </Grid>

                    
                    
                </Grid>
            </Container>
}

export default UserHomeServiciosDetalleServicio;