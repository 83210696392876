import * as React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import LoopIcon from '@mui/icons-material/Loop';
import useAxios     from '../../hooks/useAxios';
import StateContext from '../../helpers/ContextState';
import UploadImportDocuments from '../../components/Theme/Upload/UploadImportDocuments';
import UploadImportDocuments2 from '../../components/Theme/Upload/UploadImportDocuments2';
import { NavLink } from 'react-router-dom';


export default function CustomizedInputBase({ href, 
                                              customAdd, 
                                              getInit, 
                                              upload, 
                                              download,
                                              name, 
                                              label, 
                                              defaultValue, 
                                              variant, 
                                              size, 
                                              onChange, 
                                              placeholder, 
                                              fullWidth, 
                                              type, 
                                              required, 
                                              sx , 
                                              add, 
                                              handleEventSearch }) {
  const axios                       =   useAxios();
  const context                     =   React.useContext(StateContext);
  const [active,setActive]          =   React.useState(false)
  const [isLoading,setIsLoading]    =   React.useState(false)
  const [inputValue, setInputValue] =   React.useState(defaultValue || ''); // Estado para el valor del input

  const handleEvent=(reset)=>{
    
    if(reset){
      handleClear()
      handleEventSearch(false)
      return  setActive(false)      
    }

    if (active&&inputValue==='') {
      handleClear()
      handleEventSearch(false)
      setActive(false)
    } else if(inputValue!==''){
      handleEventSearch(true)
      setActive(true)
    }
  }

  const handleClear = () => {
    document.getElementById("search").value="";
    setInputValue(''); // Restablecer el valor del input
  };

  const handleChange = (event) => {
    event.preventDefault()
    setInputValue(event.target.value); // Actualizar el valor del input
    onChange(event); // Llamar a la función onChange pasada como prop, si existe
    if (event.target.value==='') {
      handleEventSearch(false)
      setActive(false)
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleEvent();
    }
    if (event.key === 'Escape') {
      handleEvent("reset");
    }
  };

  const handleDownload=()=>{
    setIsLoading(true)
    axios.getData({},download+"&download=true").then((response)=>{
      if (response&&response.data) {
        setIsLoading(false)
        if (response.data.download) {
          window.location.href=response.data.download
        }       
      }
    })
  }

  const handleUpload=()=>{
    setIsLoading(true)
    //UploadImportDocuments
    context.setOpenDialog({
      open:true,
      component:<UploadImportDocuments getInit={getInit} axios={axios} to={upload}/>
    })
  }

  const handleUpload2=()=>{
    context.setOpenDialog({
      open:true,
      component:<UploadImportDocuments2 getInit={getInit} axios={axios} to={customAdd}/>
    })
  }

  return (
    <Paper
      sx={{ display: 'flex', alignItems: 'center', }}
    >
      <IconButton sx={{ p: '10px' }} aria-label="menu"></IconButton>
      <InputBase
        required={required||false}
        type={type||"text"}
        fullWidth={fullWidth}
        name={name}
        label={label}
        id={"search"}
        value={inputValue} // Usar el valor del estado inputValue como valor del input
        variant={variant}
        size={size}
        onChange={handleChange} // Usar la función handleChange para actualizar el estado
        onKeyDown={handleKeyDown} // Agregar el evento onKeyDown para detectar la tecla Enter
        placeholder={placeholder || label}
        sx={{ ml: 1, flex: 1 }}
        inputProps={{ 'aria-label': 'search' }}
      />

      <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={()=>handleEvent("reset")}>
        {active?<HighlightOffIcon/>:<SearchIcon />}
      </IconButton>
      {
        add&&!href&&(
          <>
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions" onClick={()=>add(true)}>
              <AddIcon />
            </IconButton>
          </>
        )
      }
      {
        download&&(
          <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions" onClick={()=>handleDownload()}>
            {isLoading?<LoopIcon className="spin"  color="primary"/>:<CloudDownloadIcon />}
          </IconButton>
        )
      }
      {
        upload&&(
          <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions" onClick={()=>handleUpload()}>
            <ImportExportIcon />
          </IconButton>
        )
      }
      {
        customAdd&&(
          <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions" onClick={()=>handleUpload2()}>
            <ImportExportIcon />
          </IconButton>
        )
      }
      {
        href&&(
          <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions"  component={NavLink} to={href}>
            <AddIcon />
          </IconButton>
        )
      }
    </Paper>
  );
}
