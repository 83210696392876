import Grid     from    '@mui/material/Grid';
import Button   from    '@mui/material/Button';
import Input    from    '../../Input';
import useFormData  from '../../../hooks/useFormData';
import useAxios  from '../../../hooks/useAxios';
import React from 'react';

const Basic=({data})=>{
    const { formData, onChange, setFormData }            =   useFormData();
    const axios                             =   useAxios();

    const onSubmit=(e)=>{
        e.preventDefault()
        axios.putData(formData).then((response)=>{
            if (response&&response.data&&response.data.id) {
                //getInit(true)                
            }
        })
    }

    React.useEffect(()=>{
        if (data) {
            setFormData(data)
        }
    },[data,setFormData])

    return (
        <form onSubmit={onSubmit}>
              
            
                <Grid container spacing={2} >
                    <Grid item xs={12} md={6}>
                        <Input defaultValue={data.name} required onChange={onChange} size="small" fullWidth name="name" type="text" label="Nombre"/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Input defaultValue={data.surname} required onChange={onChange} size="small" fullWidth name="surname" type="text" label="Apellido"/>
                    </Grid>
                </Grid>


                <Grid container spacing={2} >
                    <Grid item xs={12} md={6}>
                        <Input defaultValue={data.email} required onChange={onChange} size="small" fullWidth name="email" type="email" label="Correo electrónico"/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Input defaultValue={data.celular}  required onChange={onChange} size="small" fullWidth name="celular" type="text" label="Celular"/>
                    </Grid>
                </Grid>


                <Grid container spacing={2} justifyContent={"center"}>
                    <Grid item xs={12} sm={3} md={2}>
                        <img src={data.cedula} className='img-fluid' alt="Programandoweb"/>
                    </Grid>
                    <Grid item xs={12} sm={3} md={2}>
                        <img src={data.camara_comercio} className='img-fluid' alt="Programandoweb"/>
                    </Grid>
                    <Grid item xs={12} sm={3} md={2}>
                        <img src={data.rut} className='img-fluid' alt="Programandoweb"/>
                    </Grid>
                </Grid>

                <Grid container justifyContent="right">
                    <Grid item xs={12} md={6} align="right" sx={{mt:2}}>
                        <Button variant="contained" type="submit">
                            Guardar
                        </Button>
                    </Grid>
                </Grid>            

        </form>
    )
}
export default Basic