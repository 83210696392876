import * as React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import ButtonGroup from '@mui/material/ButtonGroup';
import useAxios      from '../../hooks/useAxios';

const buttons = [
  { label: 'En espera', value: 0 },
  { label: 'Activo', value: 1 },
  { label: 'Rechazado', value: 9 },
];

export default function GroupSizesColors({ defaultValue ,id }) {
    const axios =   useAxios();
    const [selectedValue, setSelectedValue] = React.useState(defaultValue);
    //const [laoding, setLoading]             = React.useState(false);

    const handleButtonClick = (value) => {

        const url   =   'api/v1/dashboard/clienteStatusChange';
        setSelectedValue(value);
        //setLoading(true)
        axios.putData({status:value, user_id:id},url).then((response)=>{
            //setLoading(false)
        })       

    };

    



    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                '& > *': {
                m: 1,
                },
            }}
        >
            <ButtonGroup size="large" aria-label="large button group">
                {buttons.map((button) => (
                <Button
                    key={button.value}
                    onClick={() => handleButtonClick(button.value)}
                    variant={selectedValue === button.value ? 'contained' : 'outlined'}
                >
                    {button.label}
                </Button>
                ))}
            </ButtonGroup>
        </Box>
    );
}
