import  React from  'react';
import  Grid  from  '@mui/material/Grid';
import StateContext from '../../helpers/ContextState';
import Loading from '../Loading/LinearWithLabel'
const   Container   =     React.forwardRef((props, ref) => {
    
    const context               =   React.useContext(StateContext);
    
    return  <Grid ref={ref} sx={{position:"relative", height:400}}>
              {
                context.loading&&(
                  <Grid sx={{   position:"absolute", 
                                width:"100%", 
                                height:"100%", 
                                backgroundColor: "rgba(0, 0, 0, 0.0)",
                                zIndex:10, display: 
                                "flex", 
                                justifyContent:"center", 
                                alignItems: "center"
                            }}>
                    {
                      context.loading&&(<Loading increment={25}/>)
                    }
                  </Grid>
                )
              }              
              <Grid>
                {
                  !context.loading&&(props.children)
                }
              </Grid>              
            </Grid>
});


export default Container
