import React, { useState } from 'react';
import Container from '../../components/Theme/AuthForm';
import Input from '../../components/Input/InputLabel';
import Select from '../../components/Select';
import useFormData  from '../../hooks/useFormData';
import { Button, Grid, Typography } from '@mui/material';
import Upload from '../../components/Cropper/UploadNew3';
//import useAxios     from '../../hooks/useAxios';
import { useNavigate } from "react-router-dom";
import StateContext from '../../helpers/ContextState';
import useAsyncStorage   from '../../hooks/useAsyncStorage';

const storage     =   "register-user"
const storage2    =   "master";
let userStorage   =   false;
const Login=()=>{
  const context                 =   React.useContext(StateContext)
  const navigate                =   useNavigate();
  userStorage                   =   useAsyncStorage(storage);
  const [data,setData]          =   useState(false)
  const { 
          formData,
          onChange,
          setFormData 
        } =   useFormData();

  const onSubmit = async (e)=>{
    e.preventDefault()

    context.setOpenDialog({
      loading:true
    })

    await userStorage.setDataToStorage(storage,formData).then((response)=>{
      return navigate("/auth/register_contract");
    })

    /*
    await axios.postData(formData).then((response)=>{
      if (response&&response.status==='success') {
        return navigate("/auth/register_code");
      }
    })
    */
    context.setOpenDialog(false)
  }

  React.useEffect(()=>{
    userStorage.getDataFromStorage(storage2).then((response)=>{
      setData(response)
    })    
  },[])

  
  return  <Container label="Datos de registro"  onSubmit={onSubmit}>
              <Grid container spacing={2}>                
                <Grid item xs={12} md={6}>
                  <Input
                    variant="border"
                    sx={{mb:{md:1,xs:0},width:"100%"}} 
                    required 
                    onChange={onChange}  
                    fullWidth 
                    name="razon_social" 
                    type="text" 
                    label="Razón social"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    variant="border"
                    sx={{mb:{md:1,xs:0},width:"100%"}} 
                    required 
                    onChange={onChange}  
                    fullWidth 
                    name="nit" 
                    type="text" 
                    label="NIT"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    variant="border"
                    sx={{mb:{md:1,xs:0},width:"100%"}} 
                    required 
                    onChange={onChange}  
                    fullWidth 
                    name="name" 
                    type="text" 
                    label="Nombres Representante Legal"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    variant="border"
                    sx={{mb:{md:1,xs:0},width:"100%"}} 
                    required 
                    onChange={onChange}  
                    fullWidth 
                    name="surname" 
                    type="text" 
                    label="Apellidos Representante Legal"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Select
                    data={formData}
                    items={data&&data.tipo_documento?data.tipo_documento:[]}
                    variant="standard"
                    sx={{mb:{md:1,xs:0},width:"100%"}} 
                    required 
                    onChange={setFormData}  
                    fullWidth 
                    name="tipo_documento_representante_legal" 
                    type="text" 
                    label="Tipo Documento Rep. Legal"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    variant="border"
                    sx={{mb:{md:1,xs:0},width:"100%"}} 
                    required 
                    onChange={onChange}  
                    fullWidth 
                    name="documento_representante_legal" 
                    type="text" 
                    label="Nro Documento Rep. Legal"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    variant="border"
                    sx={{mb:{md:1,xs:0},width:"100%"}} 
                    required 
                    onChange={onChange}  
                    fullWidth 
                    name="email" 
                    type="text" 
                    label="Correo electrónico"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    variant="border"
                    sx={{mb:{md:1,xs:0},width:"100%"}} 
                    required 
                    onChange={onChange}  
                    fullWidth 
                    name="celular" 
                    type="text" 
                    label="Teléfono"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    variant="border"
                    sx={{mb:{md:1,xs:0},width:"100%"}} 
                    required 
                    onChange={onChange}  
                    fullWidth 
                    name="password" 
                    type="password" 
                    label="Clave"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    variant="border"
                    sx={{mb:{md:1,xs:0},width:"100%"}} 
                    required 
                    onChange={onChange}  
                    fullWidth 
                    name="password2" 
                    type="password" 
                    label="Verificar Clave"
                  />
                </Grid>
                <Grid item xs={12} >
                  <Typography variant="h6">
                    Documentos adjuntos
                  </Typography>  
                </Grid>
                <Grid item xs={12} md={6}>
                  <Upload onChange={setFormData} data={formData} name="rut" label="RUT"/>                  
                </Grid>
                <Grid item xs={12} md={6}>
                  <Upload onChange={setFormData} data={formData} name="camara_comercio" label="Cámara de comercio"/>                  
                </Grid>
                <Grid item xs={12} md={6}>
                  <Upload onChange={setFormData} data={formData} name="cedula" label="Cédula Rep. Legal"/>                  
                </Grid>

                <Grid item xs={12} align="right" sx={{mb:5}}>
                  <Button variant='contained' type="submit">
                    Crear Cuenta
                  </Button>
                </Grid>
              </Grid>              
          </Container>
}
export default Login
