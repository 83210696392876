import React, { useState } from 'react';
import { Button, CardActions, CardContent, Grid, Typography, Box }       from '@mui/material';
import Container      from '../../components/Theme/DashboardUsuario';
import useAsyncStorage   from '../../hooks/useAsyncStorage';
import CardMedia from '@mui/material/CardMedia';
import useAxios       from '../../hooks/useAxios';
import Avatar        from  '../../components/Avatar';
import StateContext   from '../../helpers/ContextState';
import RenderHtml from '../../components/RenderHtml';
import Textarea from '../../components/Input/NativeTextarea';


import io from 'socket.io-client';
const URL = process.env.REACT_APP_SOCKET;


let getInit;
const endPoint1                       =   'https://www.diablillas.com/wp-json/wp/v2/product?per_page=5';

const ModalComponent  = ({row})  =>{
  
  const base_image  =   (row.yoast_head_json&&row.yoast_head_json.og_image&&row.yoast_head_json.og_image[0])||""
  const image       =   base_image.url;
  //console.log(row.link)
  return  <Grid >
            <CardMedia
              component="img"
              height= {
                        base_image.height>800&&base_image.height<800?(base_image.height/10):base_image.height/14
                      }                    
              image={image}
              alt="Paella dish"
            />
            <CardContent sx={{height:"40vh",overflowY:"scroll"}}>
              {
                /*
                  ingeeniardeveloper
                  **LuisG2023**
                */
                /*
                  <Typography variant='h6'> 
                    {row.title&&row.title.rendered}
                  </Typography>
                */
              }              
              <Typography variant='p'> 
                <RenderHtml string={row.title&&row.content.rendered}/>
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small" variant="contained" sx={{mr:1}}>Contratar</Button>              
              <Button size="small" variant="outlined" component={"a"} href={row.link} target="_blank">Concerla</Button>
            </CardActions>
          </Grid>
}

//const URL = "ws://localhost:5150";

let socket  = false
const sound = '../../assets/whistle.mp3';

const Web=()=>{
  const messagesContainerRef            =   React.useRef(null);
  const context                         =   React.useContext(StateContext);
  const axios                           =   useAxios();
  const user                            =   useAsyncStorage("user");
  const [messages,setMessages]          =   useState([]);
  const [girls,setGirls]                =   useState([]);
  const [admin__,setAdmin__]            =   useState(false);
  
  
  getInit=async()=>{
    axios.get(endPoint1).then((response)=>{
      if (response) {
        setGirls(response)
      }
    })
    axios.getData({},"api/v1/dashboard/comments/chat_me").then((response)=>{
      if (response&&response.data) {
        setMessages(response.data)
      }
    })
  }

  const OpenModal=(row)=>{
    context.setOpenDialog({ 
                            id:10,
                            component:row?<ModalComponent row={row}/>:<Grid>Data vacía...</Grid>,
    })
  }

  
  React.useEffect(()=>{
    getInit()
  },[])

  const onSubmit = async (inputs)=>{
    //playNotificationSound();
    let message_new = {
        mensaje: inputs.mensaje,
        fecha: "10/10/2023",
        autor: user.data.name,
        user_id: user.data.id
    }
    
    let messages_ = [...messages]
        messages_.push(message_new)
        setMessages(messages_)    
    
    
    socket.emit("send-private-message",{message:message_new,recipientId:admin__})

    await axios.postData({...message_new},"api/v1/dashboard/comments/send");    
    
  }

  // Una vez que los mensajes se rendericen, desplázate al último mensaje
  // Esto es temporal porque hay que pasarlo por el getInit
  React.useEffect(() => {    
    //axios.postData({items:mensajes},"api/v1/dashboard/comments/fakes");
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
    }
  }, [messages]);


  socket = io(URL, {
    transports: ['websocket'],
    withCredentials: true,
    reconnection: true,
  });

  React.useEffect(()=>{
    /*
    const onConnect = () => {
      socket.emit("get-admin");
    };
    */

    const onSetAdmin =  (data)=>{
      setAdmin__(data)      
    }

    const Sadmin  = context.user.roles.find(search=>search==='Super Admin');
    socket.emit("user-connected",{access_token:context.user.access_token,...context.user,admin:Sadmin?true:false})

    const onMessage = ({message,userId}) => {
      if(parseInt(userId)===parseInt(context.user.id)){
        setMessages((prevMessages) => [...prevMessages, message]);
        if (messagesContainerRef.current) {
          messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight+1000;
          setTimeout(() => {        
            messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
            }, 500);
        }        
      }      
    };  

    socket.on("message-user", onMessage);
    socket.on("setAdmin", onSetAdmin);
    
    return () => {
      socket.off('message-user', onMessage);
      socket.off('setAdmin', onSetAdmin);
      socket.disconnect();
    };
    
  },[context.user,context.isOnline])

  /*
  React.useEffect(() => {    
    console.log(context.newMessage)
  }, [context.newMessage]);
  */

  return  <Container>            
            <Grid sx={{pt:8, height:"100vh",}}>
              <Grid sx={{ height: '100%',  flexDirection: 'column'}}>
                <Grid sx={{ width: '100%', background: 'white', height:"12%" }}>
                  <Grid container justifyContent={"center"} spacing={1}>
                    {
                      girls&&girls.length>0&&(
                        girls.map((row,key)=>{
                          const image = (row.yoast_head_json&&row.yoast_head_json.og_image&&row.yoast_head_json.og_image[0]&&row.yoast_head_json.og_image[0].url)||""
                          return  <Grid onClick={()=>{OpenModal(row)}} key={key} item xs={2} align="center">
                                    <Avatar sx={{width:50, height:50}} 
                                            title={(row.title&&row.title.rendered)||process.env.REACT_APP_NAME} 
                                            alt={(row.title&&row.title.rendered)||process.env.REACT_APP_NAME} 
                                            src={image}/>
                                    <Grid>
                                      <Typography sx={{fontSize:10}}>
                                        {row._regular_price}
                                      </Typography>
                                    </Grid>        
                                  </Grid>
                        })
                      )
                    }     
                  </Grid> 
                </Grid>
                <Grid sx={{ width: '100%', p:2, height:"74%",  }}>
                  <Grid sx={{ overflowY:"scroll",
                              height:"100%",
                              scrollBehavior: 'smooth',
                              }} ref={messagesContainerRef}>
                    {messages && messages.length > 0 && (
                      messages.map((row, key) => {
                        return (
                          <Grid
                            key={key}
                            sx={{
                              width:"100%",
                              display: 'flex',
                              justifyContent: row.user_id === user.data.id ? 'flex-end' : 'flex-start',
                              mb: 1,
                            }}
                          >
                            <Box
                              sx={{
                                background: row.user_id === user.data.id ? '#999' : '#333',
                                color: 'white',
                                p: 1,
                                borderRadius: '4px',
                                width:"80%",
                              }}
                            >
                              <Grid container>
                                <Grid item xs={9}>
                                  <Typography variant='p' sx={{fontSize:13}}>
                                    {row.mensaje}
                                  </Typography>
                                </Grid>           
                                <Grid item xs={3} align="right">
                                  <Typography variant='p' sx={{fontSize:10}}>
                                    {row.fecha}
                                  </Typography>
                                </Grid>                   
                              </Grid>                            
                            </Box>
                          </Grid>
                        );
                      })
                    )}
                  </Grid>  
                </Grid>
                <Grid sx={{ p:1, height:"12%"}} >
                  <Grid  sx={{my:1, background:"white", height:"auto", borderRadius:6, border:2}} >
                    <Textarea name={"mensaje"} onSubmit={onSubmit} />                                      
                  </Grid>
                </Grid>                
              </Grid>
            </Grid>
          </Container>
}
export default Web

