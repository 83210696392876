import React from 'react';
import Table from "../../components/Table/Table4";
import Container    from '../../components/Theme/Public';
import useAxios from '../../hooks/useAxios';
import { Paper, Grid, Typography } from '@mui/material';

import StateContext from '../../helpers/ContextState';
let axios=false;
let context=false;
const Home=()=>{
  const [data, setData]   =   React.useState({})
  axios                   =   useAxios();
  context                 =   React.useContext(StateContext);

  React.useEffect(()=>{
    context.setOpenBackdrop("modal")
    const getInit=()=>{
      axios.getData().then((response)=>{
        if (response&&response.data) {
          setData(response.data)  
        }        
        context.setOpenBackdrop(false)
      })
    }
    getInit()
  },[])
  return  <Container>
            
            {
              data.cliente&&(
                <Paper sx={{width:{xs:"90%",md:"90%"},p:2}}>
                  {
                    data.cliente&&(
                      <>
                        <Grid container>
                          <Grid item xs={12} md={"auto"}>
                            <Typography variant="h5">
                              {data.cliente} ( {data.cliente_celular} )
                            </Typography>
                          </Grid>                
                        </Grid>
                        <Grid container>
                          <Grid item xs={12} md={4}>
                            Código consecutivo: <b>{data.id}</b>
                          </Grid>                
                          <Grid item xs={12} md={4}>
                            <b>{data.fecha}</b>
                          </Grid>                
                          <Grid item xs={12} md={4}>
                            Estado <b>En tránsito</b>
                          </Grid>                
                        </Grid>
                        <Grid container sx={{mt:0,p:2, background:"#f2f2f2"}} align="center">
                          <Grid item xs={12} md={3}>
                            Tipo carga: <br/>
                            <b>{data.tipo_carga}</b>
                          </Grid>                
                          <Grid item xs={12} md={3}>
                            Tipo Servicio: <br/>
                            <b>{data.tipo_servicio}</b>
                          </Grid>                
                          <Grid item xs={12} md={3}>
                            Tipo Vehículo: <br/>
                            <b>{data.tipo_vehiculo}</b>
                          </Grid>
                          <Grid item xs={12} md={3}>
                            Modalidad: <br/>
                            <b>{data.modalidad}</b>
                          </Grid>                
                        </Grid>
                        <Grid container sx={{mt:2}}>
                          <Grid item xs={12}>
                            <Table data={data.direcciones}/>
                          </Grid>                                      
                        </Grid>
                      </>
                    )
                  }              

                </Paper>
              )
            }
            
          </Container>
}
export default Home
