import { Typography, Grid, Button } from '@mui/material';
import Container    from '../../../components/Theme/DashboardUsuario2';
import useAsyncStorage   from '../../../hooks/useAsyncStorage';
import React from 'react';
import Resumen from '../../../components/Resumen';
import useAxios     from '../../../hooks/useAxios';
import StateContext from '../../../helpers/ContextState';
import { useNavigate } from "react-router-dom";


const component                       =   'orden-servicios';
const title                           =   'Facturación';
let componentStorage                  =   false;
const Home=()=>{
  const context                       =   React.useContext(StateContext);
  const navigate                      =   useNavigate();
  const {postData}                    =   useAxios();  
  const master                        =   useAsyncStorage("master");
  const user                          =   useAsyncStorage("user");
  const [inputs,setInputs]            =   React.useState({})
  componentStorage                    =   useAsyncStorage(component);

  React.useEffect(()=>{
    
    if(componentStorage.data){
      setInputs(componentStorage.data)
    }   

  },[master.data])


  React.useEffect(()=>{
    
    if (user.data) {
      setInputs((prevInputs) => ({ ...prevInputs, ...user.data, ...componentStorage.data }));
    } 

  },[user.data])
  
  const onSubmit= async(e)=>{
    e.preventDefault();
    
    //return console.log(inputs)
    context.setOpenBackdrop("modal")
    await postData(inputs).then((response)=>{
      context.setOpenBackdrop(false)
      try {
        if (response.data&&response.data.id) {
          context.setOpenMessage("Orden creada con éxito")
          componentStorage.removeData()
          return navigate("/dashboard");
        }else{
          context.setOpenMessage("Problemas al crear su orden de servicio, contacte al administrador")
        }       
      } catch (error) {
        console.error(error)
      }      
    })         
  }
  
  return  <Container >
            <Grid component={"form"} onSubmit={onSubmit}>
              <Typography variant='h3' className='text-secondary' align="center">
                {title}
              </Typography>
              <Grid >
                <Grid container justifyContent={"center"} spacing={2}>
                  <Grid item xs={11} md={7}>
                    <Grid sx={{p:2}}>
                      <Typography component={"div"}>
                        <b>Datos de facturación</b>
                      </Typography>
                      <Typography component={"div"}>
                        Documento {inputs.nit!==''&&("representante legal")}: <b>CC</b> Nro <b>{inputs.nit!==''?inputs.nit:inputs.documento_representante_legal}</b>
                      </Typography>   
                      <Typography component={"div"}>
                        {
                          inputs.razon_social!==''&&(
                            <Grid>
                              Razón Social: <b> {inputs.razon_social} </b>
                            </Grid>
                          )
                        }
                      </Typography>   
                      <Typography>
                        Correo <b>{inputs.email}</b> Celular <b>{inputs.celular}</b>
                      </Typography>  
                    </Grid>              
                  </Grid>
                  <Grid item xs={11} md={5}>
                    <Resumen inputs={inputs} label="Resumen" data={master.data}/>
                  </Grid>
                </Grid>
              </Grid>
              <Grid sx={{mt:2,p:2}}>
                <Button variant={"contained"} type="submit"> 
                  Generar Factura
                </Button> 
              </Grid>
            </Grid>
          </Container>
}
export default Home
