import React, { useState } from "react";
//import Cropper from "../Cropper/UploadNew";
import { Grid } from '@mui/material';
import Cropper from '../Cropper/UploadNew2';
export default function Page(props) {
    
    const [data,setData]    =   useState([]);
    
    const onChange=(response)=>{
        let _data   =  [...data]
        _data.push(response)
        setData(_data)   
        //props.setFormData(__data)        
    }

    return (
        <Grid componet="section" container spacing={2}> 
            <Grid item xs={3}>
                <Cropper {...props} aspect={1/1} onChange={onChange}/>
            </Grid>   
            <Grid item xs={9}>
                <Grid container spacing={1}>
                    {
                        data&&data.length>0&&(
                        data.map((row,key)=>{
                            return <Grid item xs={12} md={1} key={key}>
                                        <img className="img-fluid" src={row} alt="Programandoweb"/>
                                    </Grid>
                            }
                        )

                        )
                    }
                </Grid>
            </Grid>
        </Grid>        
    );
}
