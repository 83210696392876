import * as React from 'react';
import Grid from '@mui/material/Grid';
import CardMedia from '@mui/material/CardMedia';
//import image from './index.png';
import Cropper from './Cropper'
import getCroppedImg from './cropImage'
import './index.css';
import Loading from '../Loading'
import useAxios      from '../../hooks/useAxios';
import AddIcon from '@mui/icons-material/Add';
import { Typography } from '@mui/material';

const limit       = 30*1000000
//let imageResult   = image

export default function RecipeReviewCard({ onChange, data, name , aspect, label}) {
  const axios                     =   useAxios();
  const [reset, setReset]         =   React.useState(false);
  const [img, setImg]             =   React.useState(false);
  const [fail, setFail]           =   React.useState(false);
  const [inputs, setInputs]       =   React.useState(false);
  const [loading, setLoading]     =   React.useState(false);

  /*
  React.useEffect(()=>{
    imageResult   =   (data&&data.image)||image
  },[data])
  */
  
  React.useEffect(()=>{
    if (reset) {
      setReset(false)
    }
  },[reset])

  const handleCapture = (e) => {
    if (e.files) {
      setLoading(true)
      if (e.files.length !== 0) {
        if (e.files[0].size>=limit) {
          /*Colocar mensaje de error*/
          setFail(true)
        }
        const file        =   e.files[0];
        let reader        =   new FileReader();
        reader.onload     =   function() {
          setLoading(false)
          setImg(reader.result);
        }
        reader.readAsDataURL(file);        
      }
    }
  };

  const onCropComplete=(croppedArea, croppedAreaPixels,image_)=>{
    let inputs_                     =   {...inputs};
        inputs_.croppedArea         =   croppedArea
        inputs_.croppedAreaPixels   =   croppedAreaPixels
        inputs_.image               =   image_
        if (setInputs) {
          setInputs(inputs_)  
        }        
  }

  const subCrop=()=>{
    setLoading(true)
    async function croping(){
      let croppedImage = await getCroppedImg(
                                              inputs.image,
                                              inputs.croppedAreaPixels
                                            )
      if (inputs&&setInputs) {
        let inputs_                   =   {...inputs};
            inputs_.image             =   croppedImage
            setInputs(inputs_)  
        
        
            
        axios.postData({images:croppedImage},'api/v1/dashboard/multimedia/uploads').then((response)=>{
          if (response&&response.data&&onChange&&data&&response.data.url) {
            let _data = {...data}
            if (!_data[name]) {
              _data[name]             = response.data.path
              _data[name+"_preview"]  = response.data.url
            }
            //_data[name]               = response.data
            onChange(_data)            
          }
        })

      }
      
      if(onChange){
        //onChange(croppedImage)
        setReset(true)
      }
      
      //imageResult=croppedImage
      setImg(false)
      setLoading(false)
    }
    croping(this)
  }

  return (<div >
                  <Typography variant='h6'>
                      {label}
                  </Typography>
                  <Grid className='container-cropper'>
                    {fail||""}
                    {!reset&&!img?<>
                      
                      <Grid className="cam-content cursor-pointer">
                        <input  accept='image/*'
                                id='icon-button-file'
                                type='file'
                                capture='environment'
                                onChange={(e) => handleCapture(e.target)}/>
                        {
                          data&&data[name+"_preview"]&&data[name+"_preview"]!==''&&(
                            <CardMedia
                              component="img"
                              sx={{height:"100%"}}
                              image={data[name+"_preview"]}
                              alt="Programandoweb"
                            />
                          )
                        }
                        {
                          (!data||!data[name])&&(
                            <Grid className='photo-content'>
                              <AddIcon sx={{fontSize:30}}/>
                            </Grid>
                          )
                        }                      
                      </Grid>
                    </>:false}
                    {
                      img&&!loading&&(<Cropper aspect={aspect||2.45/1} subCrop={subCrop} onCropComplete={onCropComplete} image={img}/>)
                    }
                    {
                      loading&&(  
                                  <Grid className='flex'>
                                    <Grid>
                                      <Loading/>
                                    </Grid>
                                  </Grid>
                                )
                    }
                  </Grid>  
            </div>
          );
}
