import { Typography, Grid, Button } from '@mui/material';
import Container    from '../../../components/Theme/DashboardUsuario2';
import useAsyncStorage   from '../../../hooks/useAsyncStorage';
import { NavLink } from "react-router-dom";
import React from 'react';



const component                       =   'orden-servicios';
const step                            =   'step2';
const name1                           =   'tipo_servicio_id';
const name2                           =   'modalidad_id';
const title                           =   'Tipo de Servicio';

const Home=()=>{
  
  //const navigate                      =   useNavigate();
  const componentStorage              =   useAsyncStorage(component);
  const master                        =   useAsyncStorage("master");
  const [data1,setData1]              =   React.useState([])
  const [data2,setData2]              =   React.useState([])
  const [select,setSelect]            =   React.useState(null)
  const [select2,setSelect2]          =   React.useState(null)


  const handleEvent=async(name,type)=>{
    setSelect(type)
    try {
      let _data = {}
      if (componentStorage.data===null) {
        _data[name] = type
      }else{  
        _data       = {...componentStorage.data}
        _data[name] = type
      }

      await componentStorage.setDataToStorage(component,_data)
      

    } catch (error) {
      console.error(error)
    }    
  }

  const handleEvent2=async(name,type)=>{
    setSelect2(type)
    try {
      let _data = {}
      if (componentStorage.data===null) {
        _data[name] = type
      }else{  
        _data       = {...componentStorage.data}
        _data[name] = type
      }

      await componentStorage.setDataToStorage(component,_data)
      

    } catch (error) {
      console.error(error)
    }    
  }

  React.useEffect(()=>{
    if(componentStorage.data&&componentStorage.data[name1]){
      setSelect(componentStorage.data[name1])      
    }    
    if(componentStorage.data&&componentStorage.data[name2]){
      setSelect2(componentStorage.data[name2])      
    }    
  },[componentStorage.data])

  React.useEffect(()=>{
    
    if(master.data&&master.data[name1]){
      setData1(master.data[name1])      
    }  

    if(master.data&&master.data[name2]){
      setData2(master.data[name2])      
    }  

  },[master.data])

  
  
 
  return  <Container >
            <Grid>
              <Typography variant='h3' className='text-secondary' align="center">
                {title}
              </Typography>
              <Grid >
                <Grid container justifyContent={"center"}>
                  
                  {
                    data1&&data1.length>0&&(
                      data1.map((row,key)=>{
                        return  <Grid item xs={7} md={4} key={key}>
                                  <Grid sx={{padding:{md:3,xs:1}}}>
                                    <Button fullWidth variant={select===row.value?"contained":"outlined"} onClick={()=>handleEvent(name1,row.value)}>
                                      {row.label}
                                    </Button> 
                                  </Grid>
                                </Grid>
                      })
                    )
                  }                  

                </Grid>
              </Grid>
            </Grid>
            {select&&(
              <Grid>
                <Typography variant='h3' className='text-secondary' align="center">
                  Modalidad
                </Typography>
                <Grid >
                  <Grid container justifyContent={"center"}>
                    
                    {
                      data1&&data2.length>0&&(
                        data2.map((row,key)=>{
                          return  <Grid item xs={7} md={3} key={key}>
                                    <Grid sx={{padding:{md:3,xs:1}}}>
                                      <Button fullWidth variant={select2===row.value?"contained":"outlined"} onClick={()=>handleEvent2(name2,row.value)}>
                                        {row.label}
                                      </Button> 
                                    </Grid>
                                  </Grid>
                        })
                      )
                    }                  

                  </Grid>
                </Grid>
                <Grid>
                  <Button variant={"contained"} component={NavLink} to={"../"+step}> 
                    Continuar
                  </Button> 
                </Grid>
              </Grid>
              
            )}            
          </Container>
}
export default Home
