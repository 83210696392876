import { Grid, Typography, Button } from "@mui/material"
import "./index.css"
import image from '../../assets/png/fabric.png';

const Header=()=>{
    return  <Grid component={"section"} className="section-2">
                <Grid container justifyContent={"center"} sx={{py:3}}>  
                    <Grid item xs={8}>
                        <img className="img-fluid" src={image} />
                    </Grid>
                </Grid>                
            </Grid>
}
export default Header