import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import useAxios      from '../../hooks/useAxios';
import useAsyncStorage from '../../hooks/useAsyncStorage';
import DatosBasicos from './Components/DatosBasicos';
import DatosFacturacion from './Components/DatosFacturacion';


const keys  =   "master";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1, height:400, overflow:"hidden", overflowY:"scroll" }}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};


function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

let getInit=false

export default function BasicTabs() {
  const {getDataFromStorage}        =   useAsyncStorage(keys);
  const axios                       =   useAxios();
  const [value, setValue]           =   React.useState(0);
  const [data, setData]             =   React.useState({});
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const BACKEND = "api/" + process.env.REACT_APP_BACKEND_VERSION;

  getInit=()=>{
    axios.getData({},BACKEND+"/dashboard/user-profile").then((response)=>{
      if (response&&response.data) {
        setData(response.data)
      }    
    })
  }

  React.useEffect(()=>{
    getInit();
    getDataFromStorage(keys).then((response)=>{
      if (response) {
        
      }      
    })
  },[getDataFromStorage])

  

  const onSubmit=(e)=>{
      e.preventDefault()
      axios.postDataAttachment(data,BACKEND+"/dashboard/user-profile").then((response)=>{
          if (response&&response.data&&response.data.id) {
              //getInit(true)                
          }
      })
  }

  
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 0, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" sx={{justifyContent: 'center'}}>
          <Tab label="Datos" {...a11yProps(0)} />          
          <Tab label="Facturación" {...a11yProps(1)} />          
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <DatosBasicos data={data} setData={setData} onSubmit={onSubmit} />
      </CustomTabPanel>      
      <CustomTabPanel value={value} index={1}>
        <DatosFacturacion data={data} setData={setData} onSubmit={onSubmit}/>
      </CustomTabPanel>      
    </Box>
  );
}
