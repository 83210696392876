import * as React from 'react';
import { Grid, IconButton } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';

export default function MultilineTextFields({ name, placeholder, onSubmit }) {
    const [input, setInput] = React.useState({}); // Inicializa el estado con un objeto vacío

    const onChange = (e) => {
        setInput({ ...input, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(input);
        setInput({}); // Restablece el estado a un objeto vacío después de enviar
    };

    return (
        <form onSubmit={handleSubmit}>
            <Grid sx={{ position: "relative", height: "80%" }}>
                <Grid
                    sx={{
                        position: "absolute",
                        right: 10,
                        top: 0,
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <IconButton sx={{ background: "#052b4f" }} type="submit">
                        <SendIcon sx={{ fontSize: 30, color: "white" }} />
                    </IconButton>
                </Grid>
                <Grid
                    component={"textarea"}
                    onChange={onChange}
                    name={name}
                    placeholder={placeholder || "Mensaje..."}
                    value={input[name] || ''} // Asigna el valor del estado al textarea
                    sx={{
                        background: "transparent",
                        width: "100%",
                        height: "100%",
                        border: 0,
                        p: 1,
                        pr: 6,
                        pl: 2,
                        resize: "none", // Evita que se pueda redimensionar
                        outline: "none", // Elimina el borde al enfocar
                        ":focus": {
                            outline: "none", // También elimina el borde al enfocar
                        },
                    }}
                />
            </Grid>
        </form>
    );
}
