import { Grid, Paper, Typography } from '@mui/material';
import React from 'react';
import './index.css'
const Resumen=({label,data,inputs,component})=>{

    const [_data,_setData]  =   React.useState({...inputs,image:false})
    
    React.useEffect(()=>{

        let change  =   {...inputs};

        if (inputs.tipo_vehiculo_id) {
            
            const image     =   data.vehiculo.find(search=>search.value===inputs.tipo_vehiculo_id)
            if (image.images) {
                change= {   
                            image:image.images,
                            label:image.label
                        }
            }
        }

        if (inputs.tipo_servicio_id) {
            const servicio     =   data.tipo_servicio_id.find(search=>search.value===inputs.tipo_servicio_id)
            if (servicio.label) {
                change={...change,servicio:servicio.label}
                
            }
        }

        if (inputs.modalidad_id) {
            const modalidad     =   data.modalidad_id.find(search=>search.value===inputs.modalidad_id)
            if (modalidad.label) {
                change={...change,modalidad:modalidad.label}
                
            }
        }

        /*

        if (inputs.tipo_carga_id) {
            const tipo_carga     =   data.tipo_carga_id.find(search=>search.value===inputs.tipo_carga_id)
            /*
            if (tipo_carga.label) {
                change={...change,tipo_carga:tipo_carga_id}
                
            }
          
        }
        */


        _setData(change)    
        

    },[inputs,data])

    

    return  <Paper sx={{p:2}}>

                <Grid>
                    <Typography variant="h6" align='center'>
                        {label}
                    </Typography>
                </Grid>                
                <Grid align="center">
                    <Grid className='resumen-image-background'>
                        
                            {
                                _data.image&&(<img src={_data.image} alt="programandoweb"/>)
                            }    
                        
                    </Grid>
                    <Grid>
                        <b>
                            {
                                _data.label&&(<Grid className='resumen-image-label'>{_data.label}</Grid>)
                            }  
                        </b>  
                    </Grid>    
                </Grid>
                <Grid align="left" >
                    <Grid container spacing={1}>
                        <Grid item xs={4} align="right">
                            Servicio:
                        </Grid>
                        <Grid item xs={"auto"}>
                            <b>{_data.servicio}</b>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={4} align="right">
                            Modalidad:
                        </Grid>
                        <Grid item xs={"auto"}>
                            <b>{_data.modalidad}</b>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={4} align="right">
                            Tipo de carga:
                        </Grid>
                        <Grid item xs={"auto"}>
                            <b>{inputs.tipo_carga_id}</b>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid sx={{mt:1}}>
                    <Typography  align='left'>
                        {component}
                    </Typography>
                </Grid>                
            </Paper>
}
export default Resumen