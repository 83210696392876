import Container    from '../../components/Theme/Home';
import Header from '../../components/Header';
import BlockBox from '../../components/BlockBox';
import BlockBox2 from '../../components/BlockBox/Block2';
import { Button, Grid, Typography } from '@mui/material';
import QueryBuilderOutlinedIcon from '@mui/icons-material/QueryBuilderOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';

const Home=()=>{
  return  <Container>
            <Header/>
            <Grid align="center" sx={{my:2}}>
              <Button variant='contained' sx={{borderRadius:5}}>
                Ver más
              </Button>
            </Grid>
            <Grid align="center" sx={{my:8}}>
              <Typography component={"h1"} variant='h3'>
                Lorem Ipsum
              </Typography>
            </Grid>
            <Grid align="center" sx={{my:4}} >
              <Typography maxWidth={"80%"}>
                Lorem Ipsum es simplemente un texto ficticio de la industria de la impresión y la composición tipográfica. Lorem Ipsum ha sido el texto ficticio estándar de la industria desde el año 1500, cuando un impresor desconocido tomó una galera de tipos y la codificó para hacer un libro de muestras tipográficas. Ha sobrevivido no solo cinco siglos, sino también el salto a la composición tipográfica electrónica, permaneciendo esencialmente sin cambios. Se popularizó en la década de 1960 con el lanzamiento de hojas de Letraset que contenían pasajes de Lorem Ipsum y, más recientemente, con software de autoedición como Aldus PageMaker, que incluía versiones de Lorem Ipsum.
              </Typography>
            </Grid>
            <Grid>
              <Grid container justifyContent={"center"}>
                <Grid item xs={11} md={3} align="center">
                  <QueryBuilderOutlinedIcon sx={{fontSize:100}}/>
                  <Grid>
                    <Typography component={"h1"} variant='h3'>
                      Lorem Ipsum
                    </Typography>  
                  </Grid>                  
                </Grid>
                <Grid item xs={11} md={3} align="center">
                  <MonetizationOnOutlinedIcon sx={{fontSize:100}}/>
                  <Grid>
                    <Typography component={"h1"} variant='h3'>
                      Lorem Ipsum
                    </Typography>  
                  </Grid>                  
                </Grid>
                <Grid item xs={11} md={3} align="center">
                  <CheckCircleOutlineOutlinedIcon sx={{fontSize:100}}/>
                  <Grid>
                    <Typography component={"h1"} variant='h3'>
                      Lorem Ipsum
                    </Typography>  
                  </Grid>                  
                </Grid>
              </Grid>
            </Grid>
            <Grid>
              <BlockBox/>
            </Grid>
            <Grid>
              <BlockBox2/>
            </Grid>
          </Container>
}
export default Home
