import React from 'react';
import Container    from '../../../components/Theme/DashboardUsuario';
import { Typography, Grid } from '@mui/material';
import ProfileTabs from '../../../components/Tabs/ProfileMe'

const ProfileMe = () => {
  return (    
              <Container >
                <Grid>
                  <Typography variant='h3' className='text-secondary' align="center">
                    Perfil
                  </Typography>
                  <Grid sx={{width:{xs:400,md:800},mt:{xs:2,md:6}}}>
                    <ProfileTabs/>
                  </Grid>
                </Grid>                
              </Container>            
          );
};

export default ProfileMe;
