import React from 'react';
import StateContext from '../../../helpers/ContextState';
import Grid from '@mui/material/Grid';
import './UploadImportDocuments.css'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import BackupIcon from '@mui/icons-material/Backup';
import LinearWithLabel from '../../Loading/LinearWithLabel';

const Upload=({getInit, axios,to})=>{
  const [uploadLoading,setUploadLoading]      =   React.useState(false)
  const [downloadLoading,setDownloadLoading]  =   React.useState(false)
  const context                               =   React.useContext(StateContext);

  const handleDownload=()=>{
    setDownloadLoading(true)
    axios.getData({},to+"&format=download").then((response)=>{
      if (response&&response.data) {
        window.location.href=response.data
      }
      setDownloadLoading(false)
    })
  }

  const handleFileChange = (event) => {
    setUploadLoading(true)
    axios.postDataAttachment({file:event.target.files[0]},to+"&format=upload").then(response => {
      if (response&&response.data&&response.data.code&&response.data.code===200&&getInit) {
        getInit(true)
      }
      setUploadLoading(false)
      context.setOpenDialog(false)
    }).catch(error => {
        console.error(error);
    });
    // Limpiar el archivo seleccionado
  };


  return  <Grid sx={{p:2,m:3}}>
            <Grid container justifyContent="center" spacing={2}>
              <Grid item xs={12} md={6} align="center">
                <Grid onClick={handleDownload} className="cuadro" >
                  {downloadLoading?<Grid sx={{m:2}}>
                        <LinearWithLabel increment={40}/>
                      </Grid>:<>
                        <Grid>
                          <Grid><CloudDownloadIcon sx={{fontSize:40}}/></Grid>
                          <Grid sx={{fontSize:20}} className="uppercase">Descargar Modelo</Grid>
                          <Grid sx={{fontSize:12}}>Importante: la primera línea del excel es exclusivamente un ejemplo...</Grid>
                        </Grid>
                    </>
                  }
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} align="center">
                <Grid className="cuadro cursor-pointer">
                  {uploadLoading?<Grid sx={{m:2}}>
                        <LinearWithLabel increment={20}/>
                      </Grid>:<>
                      <input type="file" name="file" className="input" onChange={handleFileChange}/>
                      <Grid className="content-input">
                        <Grid><BackupIcon sx={{fontSize:40}}/></Grid>
                        <Grid sx={{fontSize:20}} className="uppercase">Cargar Archivo</Grid>
                        <Grid sx={{fontSize:12}}>Importante: subir perfectamente verificado...</Grid>
                      </Grid>
                    </>
                  }
                </Grid>
              </Grid>
            </Grid>
          </Grid>
}
export default Upload
