import { Grid, Typography, Button } from "@mui/material"
import "./index.css"
import image from '../../assets/png/girl-box.png';


const Header=()=>{
    return  <Grid component={"section"} className="section-1">
                <Grid container justifyContent={"center"}> 
                    <Grid item xs={11} md={4}>
                        <img src={image} className="img-fluid"/>
                    </Grid>
                    <Grid item xs={11} md={4} sx={{pt:10}}>
                        <Grid align="center">
                            <Typography component={"p"} sx={{fontSize:13, lineHeight:2, textAlign: "justify"}}>
                                Lorem Ipsum es simplemente un texto ficticio de la industria de la impresión y la composición tipográfica. Lorem Ipsum ha sido el texto ficticio estándar de la industria desde el año 1500, cuando un impresor desconocido tomó una galera de tipos y la codificó para hacer un libro de muestras tipográficas. Ha sobrevivido no solo cinco siglos, sino también el salto a la composición tipográfica electrónica, permaneciendo esencialmente sin cambios. Se popularizó en la década de 1960 con el lanzamiento de hojas de Letraset que contenían pasajes de Lorem Ipsum y, más recientemente, con software de autoedición como Aldus PageMaker, que incluía versiones de Lorem Ipsum.
                            </Typography>
                        </Grid>
                        <Grid sx={{mt:3}}>
                            <Button variant='contained' sx={{borderRadius:5}}>
                                Ver más
                            </Button>
                        </Grid>                        
                    </Grid>
                </Grid>                
            </Grid>
}
export default Header