import Container    from '../../../components/Theme/Dashboard';
import View from './View';
import usePermissions from '../../../hooks/usePermissions';

const Home=()=>{
  const permissions     =   usePermissions("profile","index",{
                                                                component:View,                                                                
                                                              });
  return  <Container>
            {
              permissions&&(
                permissions.render()
              )
            }            
          </Container>
}
export default Home
