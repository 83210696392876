import { useContext } from 'react';
import Container    from '../../components/Theme/Auth';
import Input        from '../../components/Input';
import Submit       from '../../components/Button/Loading';
import useFormData  from '../../hooks/useFormData';
import useAxios     from '../../hooks/useAxios';
import useAsyncStorage   from '../../hooks/useAsyncStorage';
import StateContext from '../../helpers/ContextState';
import { Button, Grid }  from '@mui/material';
import { useNavigate, useLocation, NavLink } from "react-router-dom";
import Typography from '@mui/material/Typography';
import useSocket from '../../hooks/useSocket';

const Login=()=>{
  const {socket}                =   useSocket();
  const master                  =   useAsyncStorage("master");
  const navigate                =   useNavigate();
  const location                =   useLocation();
  const context                 =   useContext(StateContext);
  const { formData, onChange, setFormData }  =   useFormData();
  const axios                   =   useAxios();
  const userStorage             =   useAsyncStorage("user");
  

  userStorage.getDataFromStorage("user").then((respose)=>{
    if (respose&&respose.access_token) {
      return navigate("/dashboard");
    }    
  })

  const onSubmit=(e)=>{
    e.preventDefault()
    sendDataBK();
  }

  const sendDataBK=(__data)=>{
    axios.postData({...formData,...__data}).then((response)=>{
      if (  response  &&
            response.data &&
            response.code &&
            response.code==="SUCCESS"
          ) {

        let permissions = {}
        if (response.data&&response.data.permissions) {
          response.data.permissions.map((row,key)=>{
            const split = row.name.split("_")
            if (!permissions[split[0]]) {
              permissions[split[0]] = []
            }
            return permissions[split[0]].push(split[1])
          })
        }

        if (response.data.master) {
          master.setData(response.data.master)  
        }

        

        const setStorageAndState  =  {
                                        access_token:response.data.access_token,
                                        ...response.data.user,
                                        roles:response.data.roles,
                                        permissions:permissions,
                                      };
        /*vamos a setear el estado en App.js para que esté disponible global*/
        context.setUser(setStorageAndState)

        /*vamor a buscar si tiene privilegios de Sadmin*/
        const Sadmin  = response.data.roles.find(search=>search==='Super Admin');
        socket.emit("user-connected",{access_token:response.data.access_token,...response.data.user,admin:Sadmin?true:false})
        
        /*Guardo en el storage también*/
        userStorage.setData(setStorageAndState).then(resp=>{
          context.setOpenMessage("Inicio de sesión, será redirigido a tu cuenta...")
          if (location.search==='?redirect=true' && window.history.length===0) {
            return navigate("/");
          }else {
            return navigate("/dashboard");
          }
        })
      }
    })
  }

  return  <Container onSubmit={onSubmit}>
            <Input sx={{mb:2,width:"100%"}} required onChange={onChange}  fullWidth name="email" type="email" label="Correo electrónico"/>
            <Input sx={{mb:2,width:"100%"}} required onChange={onChange}  fullWidth name="password" type="password" label="Password"/>
            <Grid container sx={{mb:2}}>
              <Grid item xs={12} aling="right">
                <Typography align="right">
                  <NavLink to="/auth/forgotPassword">                    
                      ¿Se te olvidó la contraseña?
                  </NavLink>
                </Typography>
              </Grid>              
            </Grid>
            <Submit fullWidth type="submit" label="Iniciar sesión"/>            
            <Button variant='contained' fullWidth color="secondary" to="/auth/register_natural" component={NavLink}>Crear usuario</Button>
          </Container>
}
export default Login
