import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';

const anchor='bottom'

export default function TemporaryDrawer({open,setOpen,getInit}) {
  
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, _open) => (event) => {
    getInit(true)
    setOpen(_open)
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: _open });
  };

  return (
    
      <React.Fragment>
        <Drawer
          anchor={anchor}
          open={open?true:false}
          onClose={toggleDrawer(anchor, false)}
        >
          <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
            
            onKeyDown={toggleDrawer(anchor, false)}
            align="right"
          >
            <Button variant='primary' onClick={toggleDrawer(anchor, false)}>
              <CloseIcon/> Cerrar
            </Button>
          </Box> 
          <Box sx={{minHeight:"70vh"}}>
            {
              open.component&&(<open.component data={open} getInit={getInit}/>)
            }
          </Box>         
        </Drawer>
      </React.Fragment>
      
    
  )
}