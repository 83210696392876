import { Typography, Grid, Button } from '@mui/material';
import Container    from '../../../components/Theme/DashboardUsuario2';
import useAsyncStorage   from '../../../hooks/useAsyncStorage';
import { NavLink } from "react-router-dom";
import React from 'react';
import MerchandiseValue  from '../../../components/Input/MerchandiseValue';
import Resumen from '../../../components/Resumen';


const component                       =   'orden-servicios';
const step                            =   'step9';
const title                           =   'Tipo de Vehículo';

const Home=()=>{
  
  
  const componentStorage              =   useAsyncStorage(component);
  const master                        =   useAsyncStorage("master");
  const [inputs,setInputs]            =   React.useState({})
  

  const handleEvent=async(_data)=>{
    try {     
      await componentStorage.setDataToStorage(component,_data)
    } catch (error) {
      console.error(error)
    }    
  }

  React.useEffect(()=>{
    
    if(componentStorage.data){
      setInputs(componentStorage.data)
    }   

  },[master.data,componentStorage.data])
  

  
  return  <Container >
            <Grid>
              <Typography variant='h3' className='text-secondary' align="center">
                {title}
              </Typography>
              <Grid >
                <Grid container justifyContent={"center"} spacing={2}>
                  <Grid item xs={11} md={7}>
                    <Grid sx={{p:2}}>
                      <Typography >
                        ¿Cuál es el valor declarado de la mercancía?
                      </Typography>
                      <MerchandiseValue name="merchandise_value" handleEvent={handleEvent} inputs={inputs} setInputs={setInputs}/>                      
                    </Grid>
                    <Grid sx={{p:2}}>
                      <Typography >
                        ¿Cuál es el peso de la mercancía?
                      </Typography>
                      
                    </Grid>
                  </Grid>
                  <Grid item xs={11} md={5}>
                    <Resumen inputs={inputs} label="Fechas de servicio" data={master.data}/>
                  </Grid>
                </Grid>
              </Grid>
              <Grid sx={{mt:2,p:2}}>
                <Button variant={"contained"} component={NavLink} to={"../"+step}> 
                  Guardar y Continuar
                </Button> 
              </Grid>
            </Grid>
          </Container>
}
export default Home
