import Grid     from    '@mui/material/Grid';
import Button   from    '@mui/material/Button';
import Input    from    '../../Input';
import useAsyncStorage  from '../../../hooks/useAsyncStorage';
import React from 'react';
import Select from '../../Select';
const Basic=({data, setData, onSubmit})=>{
    
    const store = useAsyncStorage("master")

    const onChange=(e)=>{
        setData({...data,[e.target.name]:e.target.value})
    }

    const onChange2=(e)=>{
        setData(e)        
    }

    

    return (
        <form onSubmit={onSubmit}>
              
                <Grid container spacing={2} sx={{pt:5}}>                
                    <Grid item xs={12} md={6}>
                    <Input
                        defaultValue={data.razon_social}
                        size="small"
                        sx={{mb:{md:1,xs:0},width:"100%"}} 
                        required 
                        onChange={onChange}  
                        fullWidth 
                        name="razon_social" 
                        type="text" 
                        label="Razón social"
                    />
                    </Grid>
                    <Grid item xs={12} md={6}>
                    <Input
                        defaultValue={data.nit}
                        size="small"
                        sx={{mb:{md:1,xs:0},width:"100%"}} 
                        required 
                        onChange={onChange}  
                        fullWidth 
                        name="nit" 
                        type="text" 
                        label="NIT"
                    />
                    </Grid>
                    <Grid item xs={12} md={6}>
                    <Input
                        defaultValue={data.representante_legal_name}
                        size="small"
                        sx={{mb:{md:1,xs:0},width:"100%"}} 
                        required 
                        onChange={onChange}  
                        fullWidth 
                        name="representante_legal_name" 
                        type="text" 
                        label="Nombres Representante Legal"
                    />
                    </Grid>
                    <Grid item xs={12} md={6}>
                    <Input
                        defaultValue={data.representante_legal_surname}
                        size="small"
                        sx={{mb:{md:1,xs:0},width:"100%"}} 
                        required 
                        onChange={onChange}  
                        fullWidth 
                        name="representante_legal_surname" 
                        type="text" 
                        label="Apellidos Representante Legal"
                    />
                    </Grid>
                    <Grid item xs={12} md={6}>                        
                        <Select items={store.data&&store.data.tipo_documento?store.data.tipo_documento:[]}
                                defaultValue={data.tipo_documento_representante_legal} 
                                data={data}
                                onChange={onChange2} 
                                name="tipo_documento_representante_legal" 
                                label="Tipo de documento"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                    <Input
                        defaultValue={data.documento_representante_legal}
                        size="small"
                        sx={{mb:{md:1,xs:0},width:"100%"}} 
                        required 
                        onChange={onChange}  
                        fullWidth 
                        name="documento_representante_legal" 
                        type="text" 
                        label="Nro Documento Rep. Legal"
                    />
                    </Grid>
                    <Grid item xs={12} md={6}>
                    <Input
                        defaultValue={data.representante_legal_email}
                        size="small"
                        sx={{mb:{md:1,xs:0},width:"100%"}} 
                        onChange={onChange}  
                        fullWidth 
                        name="representante_legal_email" 
                        type="text" 
                        label="Correo electrónico"
                    />
                    </Grid>
                    <Grid item xs={12} md={6}>
                    <Input
                        defaultValue={data.telefono}
                        size={"small"}
                        sx={{mb:{md:1,xs:0},width:"100%"}} 
                        onChange={onChange}  
                        fullWidth 
                        name="telefono" 
                        type="text" 
                        label="Teléfono"
                    />
                    </Grid>                    
                    <Grid item xs={12} md={6}>
                        <Input defaultValue={data.direccion_empresa} onChange={onChange} size="small" fullWidth name="direccion_empresa" type="text" label="Dirección"/>
                    </Grid>
                </Grid>

                <Grid container justifyContent="right">
                    <Grid item xs={12} md={6} align="right" sx={{mt:2}}>
                        <Button variant="contained" type="submit">
                            Guardar
                        </Button>
                    </Grid>
                </Grid>            

        </form>
    )
}
export default Basic