import React from 'react';
import Grid  from  '@mui/material/Grid';
import Button  from  '@mui/material/Button';
import useFormData  from '../../../hooks/useFormData';
import Container    from '../../../components/Theme/Form';
import Input        from '../../../components/Input';
//import Avatar        from '../../../components/Avatar';
//import Upload        from '../../../components/Theme/Upload';
import Autocomplete        from '../../../components/Autocomplete';
import useAxios      from '../../../hooks/useAxios';
//import Typography from '@mui/material/Typography';
import { List, ListItem } from '@mui/material';

const categorias  = [] 
const Edit = ({handleClose, data , getInit, action}) => {
  const axios                       =   useAxios();
  //const [categorias,setCategorias]  =   React.useState([])
  const [productos,setProductos]    =   React.useState([])
  const [fotos,setFotos]            =   React.useState([])
  const [active,setActive]          =   React.useState([])
  const [reset,setReset]            =   React.useState(false)
  const [items,setItems]            =   React.useState([])
  const [cliente,setCliente]        =   React.useState(0)
  const { formData,
          onChange,
          setFormData }   =   useFormData();

  const onSubmit=(e)=>{
    e.preventDefault()
    if (!action) {
      axios.postData({...formData,items:items,cliente:cliente}).then((response)=>{
        if (response&&response.data&&response.data.id&&getInit) {
          getInit(true)
          handleClose()
        }
      })
    }else {
      axios.putData({...formData,items:items,cliente:cliente}).then((response)=>{
        if (response&&response.data&&response.data.id&&getInit) {
          getInit(true)
          handleClose()
        }
      })
    }
  }

  const handleSelect=(key)=>{
    let _active       =   [...active]
        _active[key]  =   active[key]?false:true;
        setActive(_active)
  }

  const handleAdd=()=>{
    let set ={
      cliente_id:cliente,
      fotos:fotos,
      cantidad:formData.cantidad,
      precio:formData.precio,
      categoria:formData.categoria,
      productos:productos,
      productos_id:formData.productos,
    }

    let _items=[...items]
        _items.push(set)
    setItems(_items)

    setFormData({})
    setFotos([])
    setReset(true)
    setProductos(null)
    setTimeout(()=>{
      setReset(false)
    }, 1000)
  }

  React.useEffect(()=>{
    if (formData.cliente_id) {
      setCliente(formData.cliente_id)
    }
  },[formData.cliente_id])


  React.useEffect(()=>{
    if (formData.productos) {
      axios.getData({},"api/"+process.env.REACT_APP_BACKEND_VERSION+"/dashboard/cotizaciones?scrap=3&q="+formData.productos).then((response)=>{
        if (response&&response.data) {
          setFotos(response.data)
          let data  = []
          response.data.map((row,key)=>{
            return data.push(false)
          })
          setActive(data)
        }
      })
    }else {
      setFotos([])
    }
  },[axios,formData.productos])

  const handleOnChangeString=(value)=>{
    if (value===null) {
      setProductos([])
    }else {
      setProductos(value)
    }
  }

  return (  <form onSubmit={onSubmit}>
              {
                reset&&(
                  <Grid align="center" sx={{p:2}}>
                    Guardando...
                  </Grid>
                )
              }
              <Grid container spacing={2} sx={{mb:0,pt:2}} justifyContent="center">
                <Grid item xs={12} md={11}>
                  <Autocomplete label="Cliente" name="cliente_id" data={data} setFormData={setFormData} endpoint={"api/"+process.env.REACT_APP_BACKEND_VERSION+"/dashboard/clientes?format=json"}/>
                </Grid>
              </Grid>
              {
                !reset&&(
                  <Container >
                    <Grid container spacing={2} sx={{mb:2}}>
                      <Grid item xs={12} md={6}>
                        <Autocomplete label="Categoria" name="categoria" data={categorias} setFormData={setFormData} endpoint={"api/"+process.env.REACT_APP_BACKEND_VERSION+"/dashboard/cotizaciones?scrap=1"}/>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Autocomplete handleOnChangeString={handleOnChangeString} disabled={formData.categoria?false:true} label="Productos" name="productos" data={categorias} setFormData={setFormData} endpoint={"api/"+process.env.REACT_APP_BACKEND_VERSION+"/dashboard/cotizaciones?scrap=2&q="+formData.categoria}/>
                      </Grid>
                      {fotos&&(
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            {
                              fotos.map((row,key)=>{
                                return  <Grid item xs={12} md={2} key={key}>
                                          <Grid className={"img-content "+(active[key]?"active":" ")} onClick={()=>{handleSelect(key)}}>
                                            <img className={"img-fluid "+(active[key]?"active":" ")} src={row} alt="Programandoweb"/>
                                          </Grid>
                                        </Grid>
                              })
                            }
                          </Grid>
                        </Grid>
                      )}

                      {formData.productos &&(
                        <Grid item xs={12} md={4}>
                          <Input defaultValue={data.cantidad} required onChange={onChange} size="small" fullWidth name="cantidad" type="number" label="Cantidad"/>
                        </Grid>
                      )}
                      {formData.cantidad&&(
                        <Grid item xs={12} md={4}>
                          <Input disabled={formData.categoria && formData.subcategoria && formData.articulo && formData.cantidad?false:true} defaultValue={data.precio} required onChange={onChange} size="small" fullWidth name="precio" type="number" label="Precio"/>
                        </Grid>
                      )}

                      {formData.precio &&(
                        <Grid item xs={12} md={4}>
                          <Button variant="contained" color={"secondary"} onClick={handleAdd}>Agregar item</Button>
                        </Grid>
                      )}

                    </Grid>
                  </Container>
                )
              }
              {
                items&&items.length>0&&(
                  <>
                    <Grid container justifyContent="center">
                      <Grid item xs={11}>
                        <Grid container spacing={1} sx={{background:"#333",color:"white",p:2}}>
                          <Grid item xs={7} className="white">
                            Producto
                          </Grid>
                          <Grid item xs={1} align="center" className="white">
                            Cantidad
                          </Grid>
                          <Grid item xs={2} align="right" className="white">
                            Precio
                          </Grid>
                          <Grid item xs={2} align="right" className="white">
                            Total
                          </Grid>
                        </Grid>
                        <List>
                          {
                            items.map((row,key)=>{
                              return  <ListItem  key={key}>
                                        <Grid container spacing={1} >
                                          <Grid item xs={7}>
                                            <div><b>{row.nombre_producto}</b></div>
                                            {row.productos}
                                          </Grid>
                                          <Grid item xs={1} align="center">
                                            {row.cantidad}
                                          </Grid>
                                          <Grid item xs={2} align="right">
                                            {row.precio}
                                          </Grid>
                                          <Grid item xs={2} align="right">
                                            {parseFloat(row.precio) * parseFloat(row.cantidad)}
                                          </Grid>
                                        </Grid>
                                      </ListItem>
                            })
                          }
                        </List>
                      </Grid>
                    </Grid>
                    <Grid container justifyContent="right">
                      <Grid item xs={12} md={6} align="right" sx={{mt:2,p:4}}>
                        <Button variant="outlined" onClick={handleClose} sx={{mr:1}}>
                          Cancelar
                        </Button>
                        <Button variant="contained" type="submit">
                          Guardar
                        </Button>
                      </Grid>
                    </Grid>
                  </>
                )
              }
            </form>
          );
};

export default Edit;
