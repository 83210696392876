import React, { useState, useRef } from 'react';

const VoicePlayer = ({ audioSrc }) => {
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [playbackRate, setPlaybackRate] = useState(1);

  const togglePlayback = () => {
    const audioElement = audioRef.current;
    if (isPlaying) {
      audioElement.pause();
    } else {
      audioElement.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handlePlaybackRateChange = () => {
    let newPlaybackRate = 1;
    if (playbackRate < 2) {
      newPlaybackRate = playbackRate + 0.5;
    }
    if (newPlaybackRate > 2) {
      newPlaybackRate = 1;
    }
    setPlaybackRate(newPlaybackRate);
    audioRef.current.playbackRate = newPlaybackRate;
  };

  return (
    <div>
      <audio ref={audioRef} src={audioSrc} />
      <button onClick={togglePlayback}>{isPlaying ? 'Stop' : 'Play'}</button>
      <button onClick={handlePlaybackRateChange}>
        {playbackRate === 2 ? '1x' : `${playbackRate}x`}
      </button>
    </div>
  );
};

export default VoicePlayer;
