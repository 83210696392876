import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import PostAddIcon from '@mui/icons-material/PostAdd';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import useAxios      from '../../hooks/useAxios';
import Input        from '../../components/Input';
import useFormData  from '../../hooks/useFormData';
import './index.css';
import { Button } from '@mui/material';

let file  =   false

export default function DocumentUploader({ onUpload , id , valor , fecha, base, placeholder }) {
    const { formData, onChange }          =   useFormData();
    const axios                           =   useAxios();
    const [selectedFile, setSelectedFile] =   useState(null);
    const [uploading, setUploading]       =   useState(false);
    const [reset, setReset]               =   useState(false);
    const [loading, setLoading]           =   useState(false);
    
  
    const handleFileChange = (e) => {
      file = e.target.files[0];
      handleUpload(file)
      setSelectedFile(file)
    };

    const handleUpload = async (file) => {
      if (file) {
        try {
          setUploading(true);
          setSelectedFile(file);      
        } catch (error) {
          console.error('Error al subir el documento:', error);
        } finally {
          setSelectedFile(null);
          setUploading(false);
        }
      }
    };

  const handleSubmit=(e)=>{
      setLoading(true)
      e.preventDefault()
      axios.postDataAttachment({...formData,doc:file,url:id,base:base},'api/v1/dashboard/multimedia/uploads/documentos_usuarios').then((response)=>{
        if (response&&response.data&&response.data.action) {
          setLoading(false)
          onUpload(response.data.docs)
          setReset(true)
          setUploading(false);
          setSelectedFile(null);      
          setTimeout(() => {
            setReset(false)
          }, 1500);
          
        }
      return; 
    })
  }

  return (
            <Grid  component={"form"} className='container-file' onSubmit={handleSubmit}>
                {
                  uploading&&(
                    <Grid align="center">
                      Subiendo la información y documento
                    </Grid>
                  )
                }
                {
                  reset&&(
                    <Grid align="center">
                      Depurando el sistema...
                    </Grid>
                  )
                }
                {
                  !reset&&(
                    <Grid align={"center"}>
                      {
                          selectedFile&&(
                              <Grid disabled={!selectedFile || uploading}>
                                  <Grid>
                                    <CloudUploadIcon sx={{
                                      fontSize:50,
                                      mt:2
                                    }} />      
                                  </Grid>
                                  Archivo listo para subir...
                              </Grid>
                          )
                      }   
                      <>
                          <Grid className="container-file-upload cursor-pointer">
                            {
                              !selectedFile&&(
                                <>
                                  <input
                                      type="file"
                                      accept=".pdf,.doc,.docx"
                                      onChange={handleFileChange}
                                      disabled={uploading}
                                  />
                                  <PostAddIcon sx={{
                                                    fontSize:80,
                                                    mt:2
                                                }}/>
                                </>
                              )
                            }                          
                            
                          </Grid>                                
                          <Grid 
                                style={{
                                  opacity: !selectedFile ? 0.5 : 1, // Reducir la opacidad cuando está desactivado
                                  pointerEvents: !selectedFile ? 'none' : 'auto', // Deshabilitar interacciones cuando está desactivado
                                }}
                          >
                            {
                              valor&&(
                                  <Input sx={{mb:2,width:"100%"}} required onChange={onChange}  fullWidth name="valor" type="number" label="Monto pago"/>                    
                              )
                            }
                            {
                              fecha&&(
                                  <Input sx={{mb:2,width:"100%"}} required onChange={onChange}  fullWidth name="fecha" type="date" label="Fecha"/>                    
                              )
                            }
                            <Input sx={{mb:2,width:"100%"}} required onChange={onChange}  fullWidth name="name" type="text" label={placeholder||"Nombre, Ej: Documento exportación, factura de venta."}/>                
                            <Button disabled={loading} type="submit" variant="contained">Subir documento</Button>
                          </Grid>
                      </>                            
                    </Grid>                
                  )
                }                
            </Grid>
        );
}
