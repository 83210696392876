import  React from  'react';
import  Grid  from  '@mui/material/Grid';
/*import  Button  from  '@mui/material/Button';
import { NavLink }  from 'react-router-dom';*/
import  './home.css';

const   Container   =     React.forwardRef((props, ref) => {
    return  <Grid ref={ref}>
              {
                props.children
              }
            </Grid>
});


export default Container
