import React from 'react';
import GoogleMap from '../GoogleMap';
import { Button, Grid, IconButton, Paper } from '@mui/material';
import Input from '../Input';
import Textarea from '../Input/Textarea';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import './index.css'

const Direction = (props) => {
    const [count, setCount] = React.useState(0);
    const [btnShow,setBtnShow]          =   React.useState(false)
    const [inputs,setInputs]            =   React.useState({})
    const [reset,setReset]              =   React.useState(false)
    const [loading,setLoading]          =   React.useState(true)

    const incrementCount = () => {
        let _count      =   count + 1
        setCount(_count);
        const _name     =   "asistentes"
        const _inputs   =   {...inputs,[_name]:_count}
        setInputs(_inputs)
    };

    const decrementCount = () => {
        if (count > 0) {
            let _count      =   count - 1;
            setCount(_count);
            const _name     =   "asistentes"
            const _inputs   =   {...inputs,[_name]:_count}
            setInputs(_inputs)
        }
    };

    const onChange = (e) => {
        const _name     =   e.target.name
        const _inputs   =   {...inputs,[_name]:e.target.value}
        setInputs(_inputs)
        if  (
                _inputs.address_components&&
                _inputs.lat&&
                _inputs.lng&&
                _inputs.direccion&&
                _inputs.direccion!==''
            ) {
            setBtnShow(true)
        }else{
            setBtnShow(false)
        }
    };

    const onChangeMap   = (e) => {
        if (e.geometry&&e.geometry&&e.geometry.location) {
            const geometry  =   e.geometry.location; 
            const _inputs   =   {
                                    ...inputs,
                                    address_components:e.formatted_address,
                                    lat:geometry.lat(),
                                    lng:geometry.lng(),
                                }
            setInputs(_inputs)
        }
    };

    const handleSave = () => {

        
        setReset(true)
        setTimeout(()=>{
            setReset(false)
        },1000)
        if (props.setOpen) {
            props.setOpen(false);
        }
        
        if (props.setLocations) {
            let _locations = [...props.locations];
            const _inputs = { ...inputs, active: true };

            if(props.defaultValue&&props.defaultValue.lat){
                const result        =   _locations.findIndex(search=>search.lat===props.defaultValue.lat)
                _locations[result]  =   _inputs;                
            }else{
                
                if (_locations && _locations[props.contador]) {
                    _locations[props.contador] = _inputs;
                } else {
                    _locations.push(_inputs);
                }
            }
            props.setLocations(_locations);
            props.handleEvent(_locations);
    
            // Después de manejar el evento, restablece los campos a un estado inicial vacío
            setInputs({});
        }
    }

    React.useEffect(()=>{
        if (props.defaultValue===true) {
            setReset(true)
            setTimeout(() => {
                setReset(false)  
            }, 1000);
        }
        if (props.defaultValue) {
            setTimeout(() => {
                setLoading(false)  
            }, 1000);
        }
    },[props.defaultValue])
    
    

    return (
            <Paper sx={{ p: 2, mb: 2 }} className={props.active?"direction-active":"direction-desactive"}>
                {   
                    loading&&(
                        <Grid>Cargando...</Grid>
                    )
                }
                {
                    !reset&&(<GoogleMap reset={reset} setReset={setReset} onChange={onChangeMap} size="small" label={props.label} />)
                }
                {
                    !loading&&!reset&&(
                        <>
                            <Grid>
                            
                            <Input
                                size="small"
                                sx={{ mb: 1, width: '100%' }}
                                required
                                onChange={onChange}
                                fullWidth
                                name="direccion"
                                type="text"
                                label="Dirección"
                                defaultValue={props&&props.defaultValue&&props.defaultValue.direccion?props.defaultValue.direccion:""}
                            />
                            </Grid>
                            <Grid>
                            <Textarea
                                defaultValue={props&&props.defaultValue&&props.defaultValue.descripcion?props.defaultValue.descripcion:""}
                                size="small"
                                sx={{ mb: 1, width: '100%' }}
                                required
                                onChange={onChange}
                                fullWidth
                                name="descripcion"
                                label="Descripción"
                            />
                            </Grid>
                            <Grid container sx={{mt:1}}>
                            <Grid item xs="auto" sx={{ mt: 1 }}>
                                Necesitas asistentes
                            </Grid>
                            <Grid item xs={1} align="center">
                                <IconButton onClick={decrementCount}>
                                    <RemoveIcon />
                                </IconButton>
                            </Grid>
                            <Grid item xs={1} align="center">
                                <Grid sx={{ mt: 1 }}>{count}</Grid>
                            </Grid>
                            <Grid item xs={1} align="center">
                                <IconButton onClick={incrementCount}>
                                    <AddIcon />
                                </IconButton>
                            </Grid>
                            <Grid item xs={5} align="right">
                                {
                                    btnShow&&(
                                        <Button onClick={handleSave} variant="contained">
                                            <SaveIcon /> Guardar
                                        </Button>                                        
                                    )
                                }       
                                <IconButton onClick={()=>{props.setOpen(false);setLoading(true)}}>
                                    <RemoveIcon />
                                </IconButton>
                            </Grid> 
                            </Grid>
                        </>    
                    )
                }
                
            </Paper>
    );
};

export default Direction;
