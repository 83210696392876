import React from 'react';
import Grid  from  '@mui/material/Grid';
import { Typography } from '@mui/material';
import Tabs from '../../../components/Tabs/Transportadores';

const Edit = ({data}) => {
  return (  
              <Grid sx={{pl:2,pr:2}}>
                <Typography variant="h5" sx={{background:"#f2f2f2"}}>
                  Datos de Servicio                  
                </Typography>
                <Grid container spacing={2} >
                  <Grid item xs={12} md={5}>
                    Usuario: <b>{ data.cliente}</b>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    Código:<b>0001</b>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    Fecha:<b>18/10/2023</b>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    Estado Servicio: <b>Activo</b>
                  </Grid>
                </Grid>
                <Grid sx={{mt:2}}>
                  <Tabs data={data}/>
                </Grid>            
              </Grid>            
          );
};

export default Edit;
