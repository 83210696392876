import { Grid, Typography } from "@mui/material"
import "./index.css"
import image from '../../assets/png/imagen-header.png';
import Menu from './Menu';

const Header=()=>{
    return  <Grid component={"header"} className="header">
                <Grid sx={{pt:3}}>
                    <Menu/>
                </Grid>
                <Grid  align="center" sx={{paddingTop:5}}>
                    <Typography component={"h1"} className="header-title">
                        Global Trucker
                    </Typography>
                </Grid>
                <Grid className="header-image">
                    <img src={image} className="img-fluid"/>
                </Grid>
            </Grid>
}
export default Header