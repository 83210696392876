/*set components*/
import Home  from './Home';

/*set icons*/
import Face3Icon from '@mui/icons-material/Face3';


export const routes_modules = [
  {
    name: "Clientes",
    path: "/",
    component: Home,
    menu: true,
    private: true,
    icon: <Face3Icon />,
    title: "Clientes",
    subtitle: "Impulsan tu negocio"
  },

];
