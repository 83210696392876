import React from 'react';
import { Breadcrumbs, Link } from '@mui/material';
import { NavLink }  from 'react-router-dom';
import { useLocation } from "react-router-dom";

const BreadcrumbsWithLinks = (props) => {
  const location      =   useLocation();
  const currentPath   =   location.pathname
  const pathSegments  =   currentPath.split('/').filter(Boolean);
  const breadcrumbs   =   pathSegments.map((segment, index) => ({
    label: segment,
    url: `/${pathSegments.slice(0, index + 1).join('/')}`,
  }));

  return (
    <Breadcrumbs aria-label="breadcrumb">
      {breadcrumbs.map((breadcrumb, index) => (
        <Link
          className="capitalized-text"
          component={NavLink}
          key={index}
          to={breadcrumb.url}
          color={index === breadcrumbs.length - 1 ? 'text.primary' : 'inherit'}
        >
          {breadcrumb.label}
        </Link>
      ))}
    </Breadcrumbs>
  );
};

export default BreadcrumbsWithLinks;
