import * as React from 'react';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { IconButton, Paper } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

export default function InputAdornments({ inputs, setInputs, name, handleEvent, skipInput }) {
  const [input, setInput] = React.useState('');
  const [list, setList] = React.useState([]);
  const [error, setError] = React.useState('');

  const onChange = (e) => {
    setInput(e.target.value);
    setError(''); // Limpia el error al cambiar el valor
  };

  const handleSave = () => {
    // Validar que el input no esté vacío
    if (!input) {
      setError('Por favor, ingresa una fecha válida.');
      return;
    }

    // Validar que la fecha sea mayor a la fecha de hoy
    const inputDate = new Date(input);
    const today = new Date();

    if (inputDate <= today) {
      setError('La fecha debe ser mayor a la fecha de hoy.');
      return;
    }

    if (setInputs && name) {
      let _inputs = { ...inputs };
      if (!_inputs[name]) {
        _inputs[name] = [];
      }
      const result = _inputs[name].find((search) => search === input);
      if (!result) {
        _inputs[name].push(input);
      }
      setInputs(_inputs);
      handleEvent(_inputs)
      setList(_inputs[name]);
      setInput(''); // Limpia el input después de guardar
      setError(''); // Limpia el error después de guardar
    }
  };

  React.useEffect(()=>{
    if (list.length===0&&inputs&&inputs[name]) {
        setList(inputs[name])
    }
  },[inputs,name])

  return (
    <Grid container spacing={1}>
        {
            !skipInput&&(
                <TextField  error={!!error}
                            helperText={error}
                            type="date"
                            fullWidth
                            onChange={onChange}
                            value={input}
                            sx={{ m: 1, width: '100%' }}
                            InputProps={{
                            startAdornment: (
                                <Grid position="start" className="bg-primary" sx={{ p: 1 }}>
                                <CalendarMonthIcon />
                                </Grid>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                <IconButton onClick={handleSave}>
                                    <AddIcon sx={{ fontSize: 30 }} />
                                </IconButton>
                                </InputAdornment>
                            ),
                            }}
                            variant="standard"
                />
            )
        }
      
    {
        list.map((row, key) => {
            return (
            <Grid item xs={'auto'} key={key}>
                <Paper sx={{ p: 2, mb: 1 }}>{row}</Paper>
            </Grid>
            );
        })
    }
    </Grid>
  );
}
