import { Button, Grid, Typography } from '@mui/material';
import Container    from '../../../components/Theme/DashboardUsuario2';
import { NavLink } from 'react-router-dom';
import useAsyncStorage   from '../../../hooks/useAsyncStorage';
import { useNavigate } from "react-router-dom";
const Home=()=>{
  const navigate                =   useNavigate();
  const userStorage             =   useAsyncStorage("user");
  const handleClosewSession=()=>{
    userStorage.removeData();
    return navigate("/auth/login");
  }
  return  <Container >
            <Grid align="center" sx={{pt:10, mt:{xs:1,md:5}}}>
              <Typography sx={{mb:1}}>
                ¿Está seguro de querer salir?
              </Typography>
              <Button variant='contained' sx={{mr:2}} onClick={handleClosewSession}>
                Si
              </Button>
              <Button component={NavLink} variant='contained' color="secondary" to="/dashboard">
                No
              </Button>
            </Grid>
          </Container>
}
export default Home
