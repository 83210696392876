import  React from  'react';
import  Grid  from  '@mui/material/Grid';
import  Box  from  '@mui/material/Box';
import  Avatar  from  '../Avatar';
import  logo  from  '../../assets/png/logo192.png';
import  './auth.css';
const   Container   =     React.forwardRef((props, ref) => {
    return  <Grid ref={ref} className="auth-content">
              <Grid>
                <Grid align="center">
                    <Avatar sx={{width:{xs:200},height:200, mt:-10}} 
                            title={process.env.REACT_APP_NAME} 
                            alt={process.env.REACT_APP_NAME} 
                            src={logo}
                    />
                </Grid>
                <Box component={props.onSubmit?"form":""} noValidate={props.noValidate||false} autoComplete="off" onSubmit={props.onSubmit}>
                  <Grid className={props.className||"auth-form"}>
                    {props.children}
                  </Grid>
                </Box>                
              </Grid>
            </Grid>
});


export default Container
//#fb19ff
