import React from 'react';
import { Typography, Grid, Button } from '@mui/material';
import Container    from '../../../components/Theme/DashboardUsuario2';
import { NavLink } from "react-router-dom";


const step                            =   'step5';
const title                           =   'Condicones de transporte';

const Home=()=>{
  
  
  
  return  <Container >
            <Grid>
              <Typography variant='h3' className='text-secondary' align="center">
                {title}
              </Typography>
              <Grid >
                <Grid container justifyContent={"center"} spacing={2}>
                  <Grid item xs={11} md={7}>
                      <Grid>
                        <Typography sx={{mb:1.5,mt:2}}>
                          Proveedor de servicios
                          Los servicios de Google los proporciona y se contratan con:
                        </Typography>  
                        <Typography sx={{mb:1.5}}>
                          Google LLC
                          Empresa que se rige por las leyes del estado de Delaware (EE. UU.) y que opera bajo la legislación estadounidense
                        </Typography>  
                        <Typography sx={{mb:1.5}}>  
                          1600 Amphitheatre Parkway
                          Mountain View, California, 94043
                          EE. UU.
                        </Typography>  
                        <Typography>  
                          Requisitos de edad mínima
                          Si no tienes la edad mínima para poder gestionar tu propia cuenta de Google, 
                          debes contar con el permiso de tu padre, madre o tutor legal para utilizar una cuenta de Google. 
                          Pide a tu padre, madre o tutor legal que lea estos términos contigo.
                        </Typography>
                      </Grid>
                      <Grid sx={{mt:2}} align="center">
                          <Button variant={"contained"} component={NavLink} to={"../"+step}> 
                            Aceptar y continuar
                          </Button> 
                      </Grid>
                  </Grid>                  
                </Grid>
              </Grid>              
            </Grid>
          </Container>
}
export default Home
